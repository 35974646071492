import styled from 'styled-components'

export const ContainerLoading = styled.div`
  width: 100%;
  position: absolute;
  z-index: 10000;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000090;
`
