import {createContext, useContext, useEffect, useState} from 'react'
import instance from '../services/instance';
import {useLoading} from "./loading.context";

export const TelegramGruposContext = createContext({})

export const TelegramGruposProvider = ({ children }) => {
    const [grupos, setGrupos] = useState([])
    const [gruposUsuario, setGruposUsuario] = useState();
    const [tabelaUsuario, setTabelausuario] = useState();
    const [tempHotmartUsuarioId, setTempUsuarioHotmartId] = useState();
    const {setLoadingAtivo} = useLoading();
    const [linkGrupo, setLinkGrupo] = useState("")

    const GetGrupos = async () => {
        setLoadingAtivo(true)
        let listaGrupos = await instance.post("/api/LineraPay/grupos");
        setGrupos(listaGrupos.data);
        setLoadingAtivo(false)
    }

    const GetGruposUsuario = async (Id_Hotmart_Usuario) => {
        setLoadingAtivo(true)
        setTempUsuarioHotmartId(Id_Hotmart_Usuario);
        let listaGruposUsuario = await instance.post("/api/LineraPay/verGruposUsuario", {Id_Hotmart_Usuario});
        setGruposUsuario(listaGruposUsuario.data);
        setLoadingAtivo(false)
    }

    const removerDoGrupo = async (telegramIdUsuario, telegramIdGrupo, IdTelegramUsuario) => {
        await instance.post("/api/LineraPay/removerUsuario", {telegramIdUsuario, telegramIdGrupo, IdTelegramUsuario});
        GetGruposUsuario(tempHotmartUsuarioId)
    }

    const convidarProGrupo = async (IdTelegramUsuario, telegramIdGrupo, IdGrupoTelegramProduto) => {
        setLoadingAtivo(true)
        const linkDoGrupo = await instance.post("/api/LineraPay/gerarInviteUsuario", {IdTelegramUsuario, telegramIdGrupo, IdGrupoTelegramProduto});
        setLinkGrupo(linkDoGrupo.data)
        navigator.clipboard.writeText(linkDoGrupo.data);
        await GetGruposUsuario(tempHotmartUsuarioId)
        setLoadingAtivo(false)
    }

    return (
        <TelegramGruposContext.Provider value={{ grupos, setGrupos, GetGrupos, GetGruposUsuario, gruposUsuario, removerDoGrupo, convidarProGrupo, linkGrupo, setLinkGrupo }}>
            {children}
        </TelegramGruposContext.Provider>
    )
}

export function useTelegramGrupos() {
    const context = useContext(TelegramGruposContext);

    return context;
}
