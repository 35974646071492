import styled from 'styled-components'

export const ContainerCopyPaste = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  .MuiTextField-root {
    justify-content: center;
    min-width: 200px;
  }
`
