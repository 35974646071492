import React from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
    InputAdornment,
    IconButton,
    OutlinedInput, CircularProgress,
} from '@mui/material';
import logo from '../../../assets/logo.png';
import jogador1 from '../../../assets/jogador 1.png';
import {
    AlinharCentro,
    ConteudoDireito,
    Paineis,
    PainelDireito,
    PainelEsquerdo,
} from './styles';
import { Key, Person, Visibility, VisibilityOff } from '@mui/icons-material';
import loginService from '../../../services/login.service';
import { ErrorMessage } from '../../../components/styles';
import {useNavigate} from "react-router-dom";

const LoginPage = () => {
    const [emailRecuperacao, setEmailRecuperacao] = React.useState('')
    let navigate = useNavigate();

    const handleEmailRecuperacao = (event) => {
        setEmailRecuperacao(event.target.value)
    };

    const [values, setValues] = React.useState({
        amount: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        errorMessage: '',
        forgotPasswordLabel: 'Insira o e-mail cadastrado:',
        registerComponent: false,
    });

    const [messageError, setMessageError] = React.useState(false)

    const [loading, setLoading] = React.useState('')

    console.log(values.registerComponent);

    const submitHandle = (e) => {
        e.preventDefault();

        setLoading('Aguardando')
        setValues({...values, errorMessage: ''})

        loginService
            .ResetPassword(emailRecuperacao)
            .then((response) => {
                response &&
                setMessageError(true)
                setLoading('email')
            })
            .catch((err) => {
                setValues({ ...values, errorMessage: err.response.data.message });
                setLoading('');
            });
    };
    return (
        <>
            <Paineis register={values.registerComponent}>
                <PainelEsquerdo>
                    <img src={jogador1} alt='Logo' className='JogadorImg' />
                </PainelEsquerdo>
                <PainelDireito>
                    <form onSubmit={(e) => submitHandle(e)}>
                        <ConteudoDireito>
                            <img src={logo} alt='Logo' className='Logo' />
                            <>
                                {
                                    messageError &&
                                    <Typography>Aguarde alguns minutos e verifique seu e-mail.</Typography>
                                }
                                {
                                    loading === 'Aguardando' &&
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                        <CircularProgress />
                                    </div>
                                }
                                {
                                    loading === "" &&
                                    <>
                                        <Typography>{values.forgotPasswordLabel}</Typography>
                                        <OutlinedInput
                                            required
                                            id='outlined-required'
                                            placeholder='E-mail'
                                            name='emailRecuperacao'
                                            onChange={(e) => handleEmailRecuperacao(e)}
                                            startAdornment={
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    edge='end'
                                                    color='white'
                                                    style={{ marginRight: '4px' }}
                                                >
                                                    <Person />
                                                </IconButton>
                                            }
                                        />
                                    </>
                                }
                            </>
                            {values.errorMessage && (
                                <ErrorMessage>{values.errorMessage}</ErrorMessage>
                            )}
                            <div>
                                {/*<FormControlLabel*/}
                                {/*  name='manterConectado'*/}
                                {/*  control={*/}
                                {/*    <Checkbox*/}
                                {/*      defaultChecked*/}
                                {/*      onChange={(e) => handleInputChange(e)}*/}
                                {/*    />*/}
                                {/*  }*/}
                                {/*  label='Manter-me logado '*/}
                                {/*/>*/}
                                <Button
                                    onClick={() => {
                                        navigate('/')
                                    }}
                                    variant='text'
                                >
                                    Voltar
                                </Button>
                            </div>
                            <div>
                                {
                                    loading === '' &&
                                    <Button
                                        style={{ width: '100%' }}
                                        variant='contained'
                                        type='submit'
                                    >
                                        Enviar
                                    </Button>
                                }
                            </div>
                        </ConteudoDireito>
                    </form>
                </PainelDireito>
            </Paineis>
        </>
    );
};

export default LoginPage;
