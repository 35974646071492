import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    InputAdornment,
    IconButton,
    OutlinedInput, Modal, MenuItem, TextField, CircularProgress,
} from '@mui/material';
import logo from '../../../assets/logo.png';
import jogador1 from '../../../assets/jogador 1.png';
import {
    Paineis,
    PainelDireito,
    PainelEsquerdo,
} from './styles';
import {Close, Visibility, VisibilityOff} from '@mui/icons-material';
import { ErrorMessage } from '../../../components/styles';
import {
    Bar,
    BlockColumn,
    Input,
    Label, ModalStyle,
    RegisterWrap, Step,
    TermsContainer,
    Title,
} from "../OldRegister/styles";
import InputMask from "react-input-mask";
import userService from "../../../services/user.service";
import configuraService from "../../../services/configura.service";
import {useNavigate} from "react-router-dom";

const RegisterPage = () => {
    let navigate = useNavigate();
    const [moeda, setMoeda] = useState("BRL");
    const [cota, setCota] = useState("1");
    const [cupomDeDesconto, setCupomDeDesconto] = useState("");
    const [discount, setDiscount] = useState("");
    const [finalValue, setFinalvalue] = useState("");
    const [value, setValue] = useState("");
    const [paymentValue, setPaymentValue] = useState("");
    const [orderId, setOrderId] = useState("");
    const [loadingOrder, setLoadingOrder] = React.useState(false);
    const [maxQuota, setMaxQuota] = React.useState();
    const [groupValue, setGroupValue] = React.useState();
    const [ageValidationMessage, setAgeValidationMessage] =
        React.useState();
    const [cpf, setCpf] = React.useState("");
    const [processorId, setProcessorId] = React.useState();
    const [webhook, setWebhook] = React.useState();
    const [usernameError, setUsernameError] = React.useState("");
    const [usernameValidation, setUsernameValidation] = React.useState();
    const [validateEmail, setValidateEmail] = React.useState(false);
    const [birthDate, setBirthDate] = React.useState();
    const [acceptTerms, SetAcceptTerms] = React.useState();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [emailError, setEmailError] = React.useState("");
    const [step, setStep] = React.useState(1);
    const [politica, setPolitica] = React.useState("");
    const [termos, setTermos] = React.useState("");
    const [regulamento, setRegulamento] = React.useState("");
    const [cpfError, setCpfError] = React.useState("");
    const [registerForm, setRegisterForm] = React.useState({
        email: "",
        name: "",
        lastName: "",
        cpf: "",
        phone: "",
        username: "",
        cupom: "",
        password: "",
        quota: 1,
        manterConectado: true,
        acceptTerms: false,
        confirmedPassword: "",
    });
    const [modalText, setModalText] = React.useState("");
    const [errorTerms, setErrorTerms] = React.useState(false);
    const [errorPassword, setErrorPassword] = React.useState(false);
    const [validationLoading, setValidationLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('')

    const handleMoeda = (event) => {
        setMoeda(event.target.value);
    };
    const handleCota = (event) => {
        setCota(event.target.value);
    };

    const selectCotas = () => {
        const menuItens = [];
        if(maxQuota)
            for (let i = 0; i < maxQuota; i++) {
                menuItens.push(<MenuItem value={`${i + 1}`}>{i + 1}</MenuItem>);
            }

        return menuItens;
    };

    useEffect(() => {
        if(registerForm.confirmedPassword != registerForm.password)
            setErrorPassword(true)
        else
            setErrorPassword(false)
    }, [registerForm.confirmedPassword])

    const IrParaPagamento = () => {
        setLoadingOrder(true);
    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        setRegisterForm({
            ...registerForm,
            [name]: value,
        });
    };
    const [values, setValues] = React.useState({
        amount: "",
        weight: "",
        weightRange: "",
        showPassword: false,
        showConfirmedPassword: false,
        errorMessage: "",
        emailError: "",
        ForgotPasswordLabel: "Insira o e-mail cadastrado:",
        emailValidation: false,
        birthDateValidation: false,
        cpfValidation: false,
        usernameValidation: false,
        cpfError: "",
        birthDateError: "",
        usernameError: "",
        discountPercent: 0,
    });

    const submitHandle = async (e) => {
        e.preventDefault();

        setValidationLoading(true);

        if(cpfError !== ""){
            setValidationLoading(false);
            return
        }

        if(ageValidationMessage !== ""){
            setValidationLoading(false);
            return
        }

        if(!acceptTerms){
            setErrorTerms(true);
            setValidationLoading(false);
            return
        }
        else
            setErrorTerms(false);

        registerForm.username !== "" &&
        await userService
            .ValidateUsername(registerForm.username)
            .then((response) => {
                console.log(response)
                setUsernameValidation(response.data.isValid);
                setUsernameError("");
            })

            .catch((err) => {
                setUsernameError(err.response.data.message);
                setValidationLoading(false)
                return
            });
        await userService
            .ValidateEmail(registerForm.email)
            .then((response) => {
                setValidateEmail(response.data.isValid);
            })
            .catch((err) => {
                setEmailError(err.response.data.message);
                setValidationLoading(false)
                return
            });
        await userService
            .ValidateCpf(cpf)
            .then((response) => {
                setValues({
                    ...values,
                    cpfValidation: response.data.isValid,
                });
            })
            .catch((err) => {
                setCpfError(err.response.data.message);
                setValidationLoading(false)
                return
            });
    };

    useEffect(() => {
        if(
            values.cpfValidation &&
            validateEmail &&
            usernameValidation &&
            registerForm.password === registerForm.confirmedPassword &&
            ageValidationMessage === "" &&
            !errorPassword
        )
            registrarUsuario();
    }, [usernameValidation, validateEmail, values.cpfValidation, ageValidationMessage])

    const registrarUsuario = async () => {
        await userService
            .CreateUser({
                name: registerForm.name,
                lastName: registerForm.lastName,
                birthDate: birthDate,
                email: registerForm.email,
                cpf: cpf,
                country: "Brasil",
                type: 1,
                mobileNumber: registerForm.phone,
                username: registerForm.username,
                password: registerForm.password,
            })
            .then(() => {
                setErrorMessage('');
                setStep(2)
                setValidationLoading(false);
            })
            .catch(e => {
                setErrorMessage(e.response.data.message)
            })
    }

    React.useEffect(() => {
        configuraService
            .GetProcessorId()
            .then((response) => {
                setProcessorId(response.data[0].info);
                setWebhook(response.data[1].info);
            })
            .catch(() => {});

        configuraService
            .GetDocs()
            .then((response) => {
                setTermos(response.data[0].info);
                setPolitica(response.data[1].info);
                setRegulamento(response.data[2].info);
            })
            .catch(() => {});
    }, []);

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
            showConfirmedPassword: !values.showConfirmedPassword,
        });
    };

    const handleMouseDownPassword = (
        event
    ) => {
        event.preventDefault();
    };
    const handleBirthDate = (birthDate) => {
        setBirthDate(birthDate);

        const [day, month, year] = birthDate.split("/");

        const date = new Date(+year, parseInt(month) - 1, +day);

        const date18YrsAgo = new Date();
        date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
        if(day > "31")
            setAgeValidationMessage("Data Invalida.");
        else if(month > "12")
            setAgeValidationMessage("Data Invalida.");
        else if (date <= date18YrsAgo) {
            setAgeValidationMessage("");
        } else {
            setAgeValidationMessage("Você precisa ser maior de idade.");
        }
    };

    const cpfHandle = (cpf) => {
        setCpf(cpf);

        if (cpf) {
            const re = new RegExp(
                "([0-9]{2}[\\.]?[0-9]{3}[\\.]?[0-9]{3}[\\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\\.]?[0-9]{3}[\\.]?[0-9]{3}[-]?[0-9]{2})"
            );
            const ok = re.test(cpf);

            if (cpf === "" || cpf === null) setCpfError("");
            else if (ok) setCpfError("");
            else setCpfError("Insira um cpf valido");
        }
    };

    return (
        <>
            <Paineis>
                <PainelEsquerdo>
                    <img src={jogador1} alt='Logo' className='JogadorImg' />
                </PainelEsquerdo>
                <PainelDireito>
                    <form style={{height: '100%'}} onSubmit={(e) => submitHandle(e)}>
                        <RegisterWrap>
                            <img src={logo} alt="Logo" className="Logo" />

                            <Title>Cadastro</Title>

                            {step === 1 ? (
                                <>
                                    <BlockColumn>
                                        <Label>Nome</Label>
                                        <OutlinedInput
                                            required
                                            id="outlined-required"
                                            placeholder="Nome"
                                            name="name"
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </BlockColumn>
                                    <BlockColumn>
                                        <Label>Sobrenome</Label>
                                        <OutlinedInput
                                            required
                                            id="outlined-required"
                                            placeholder="Sobrenome"
                                            name="lastName"
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </BlockColumn>
                                    <BlockColumn>
                                        <Label>Usuário</Label>
                                        <Input
                                            placeholder="USUÁRIO"
                                            name="username"
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        <ErrorMessage>{usernameError}</ErrorMessage>
                                    </BlockColumn>
                                    <BlockColumn>
                                        <Label>Seu melhor E-mail</Label>
                                        <OutlinedInput
                                            required
                                            id="outlined-required"
                                            placeholder="E-mail"
                                            name="email"
                                            type="email"
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        <ErrorMessage>{emailError}</ErrorMessage>
                                    </BlockColumn>
                                    <BlockColumn>
                                        <Label>Data de Nascimento</Label>

                                        <InputMask
                                            onBlur={(e) => handleBirthDate(e.target.value)}
                                            placeholder="DD/MM/AAAA"
                                            style={{
                                                backgroundColor: "rgba(45, 69, 95, 0.8)",
                                                border: "1px solid #6885A3",
                                                boxShadow: "0px 6px 14px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "26px",
                                                fontFamily: "Montserrat",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                                color: "#FFF",
                                                padding: "16px",
                                            }}
                                            mask="99/99/9999"
                                        />

                                        {ageValidationMessage !== "" && (
                                            <ErrorMessage>{ageValidationMessage}</ErrorMessage>
                                        )}
                                        <ErrorMessage>{values.birthDateError}</ErrorMessage>
                                    </BlockColumn>
                                    <BlockColumn>
                                        <Label>CPF</Label>
                                        <OutlinedInput
                                            required
                                            id="outlined-required"
                                            placeholder="CPF"
                                            name="cpf"
                                            type="number"
                                            onBlur={(e) => cpfHandle(e.target.value)}
                                            // inputProps={{
                                            //   inputMode: 'numeric',
                                            //   pattern: '/^d{3}.d{3}.d{3}-d{2}$/',
                                            // }}
                                        />
                                        <ErrorMessage>{cpfError}</ErrorMessage>
                                    </BlockColumn>
                                    <BlockColumn>
                                        <Label>Número de Celular</Label>
                                        <OutlinedInput
                                            required
                                            id="outlined-required"
                                            placeholder="NÚMERO COM DDD"
                                            name="phone"
                                            type="number"
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                    </BlockColumn>
                                    <BlockColumn>
                                        <Label>Senha</Label>
                                        <OutlinedInput
                                            required
                                            id="outlined-adornment-password"
                                            placeholder="****"
                                            type={values.showPassword ? "text" : "password"}
                                            onChange={(e) => handleInputChange(e)}
                                            name="password"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        color="white"
                                                    >
                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />{" "}
                                    </BlockColumn>
                                    <BlockColumn>
                                        <Label>Confirmar Senha</Label>
                                        <OutlinedInput
                                            required
                                            id="outlined-adornment-password"
                                            placeholder="****"
                                            type={values.showConfirmedPassword ? "text" : "password"}
                                            value={registerForm.confirmedPassword}
                                            onChange={(e) => handleInputChange(e)}
                                            name="confirmedPassword"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        color="white"
                                                    >
                                                        {values.showConfirmedPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {errorPassword && <ErrorMessage>Senhas não são iguais</ErrorMessage> }
                                        <TermsContainer>
                                            <Checkbox
                                                onClick={() => {
                                                    SetAcceptTerms(!acceptTerms);
                                                }}
                                            />
                                            <span />
                                            <p>
                                                Tenho mais de 18 anos e aceito os{" "}
                                                <strong
                                                    style={{
                                                        color: "#00B1FF",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        setModalText(termos);
                                                        handleOpen();
                                                    }}
                                                >
                                                    Termos & Condições
                                                </strong>
                                                ,{" "}
                                                <strong
                                                    onClick={() => {
                                                        setModalText(politica);
                                                        handleOpen();
                                                    }}
                                                    style={{
                                                        color: "#00B1FF",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Política de Privacidade
                                                </strong>{" "}
                                                e o{" "}
                                                <strong
                                                    onClick={() => {
                                                        setModalText(regulamento);
                                                        handleOpen();
                                                    }}
                                                    style={{
                                                        color: "#00B1FF",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Regulamento
                                                </strong>
                                                <span className=""></span>
                                            </p>
                                        </TermsContainer>
                                        {errorTerms && <ErrorMessage>Você deve aceitar os termos.</ErrorMessage> }
                                    </BlockColumn>
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                    </div>

                                    {loadingOrder && !orderId && (
                                        <>
                                            <div
                                                style={{
                                                    marginTop: "28px",
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <CircularProgress />
                                            </div>
                                        </>
                                    )}
                                    {!loadingOrder && !orderId && (
                                        <Button
                                            onClick={() => IrParaPagamento()}
                                            style={{ width: "100%", marginTop: "28px" }}
                                            variant="contained"
                                        >
                                            ir para pagamento
                                        </Button>
                                    )}
                                    PaagButton
                                    {" "}
                                    <Modal open={open} onClose={handleClose}>
                                        <ModalStyle>{modalText}</ModalStyle>
                                    </Modal>
                                </>
                            )}
                            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                            {step === 1 && !validationLoading &&
                                <Button
                                    style={{ width: "100%", marginTop: "20px" }}
                                    variant="contained"
                                    type="submit"
                                >
                                    Enviar
                                </Button>
                            }
                            { step === 1 && validationLoading &&
                                <div
                                    style={{
                                        marginTop: "28px",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            }

                            {/*Lugar do Modal*/}
                        </RegisterWrap>
                        <Button
                            style={{ width: "100%", marginTop: "20px" }}
                            onClick={() => {
                                navigate('/')
                            }}
                        >
                            Voltar
                        </Button>

                        <Bar>
                            <div style={{ width: step === 1 ? '50%' : '100%' }} />
                        </Bar>
                        <Step>Passo {step} de 2</Step>
                    </form>
                </PainelDireito>
            </Paineis>
        </>
    );
};

export default RegisterPage;
