import {createContext, useContext, useEffect, useState} from 'react'
import instance from "../services/instance";
import {ClientesContext} from "./cliente.context";
import {useAuth} from "./autenticacao.context";

export const TelegramUsuariosContext = createContext({})

export const TelegramUsuariosProvider = ({ children }) => {
    const {user} = useAuth();
    const [lista, setLista] = useState();
    const [usuarioSelecionado, setUsuarioSelecionado] = useState();
    const [totalUsuarios, setTotalUsuarios] = useState(30);

    const GetUsuariostelegram = async (page = 1, searchText = "") => {
        let listaClientes = await instance.post("/api/LineraPay/usuarios", {ClienteId: user.ClientId, NumeroDaPagina: page, FiltroTexto: searchText});
        setLista(listaClientes.data.usuarios);
        if(totalUsuarios === 30)
            setTotalUsuarios(listaClientes.data.totalUsuarios);
    }

    return (
        <TelegramUsuariosContext.Provider value={{ lista, totalUsuarios, setLista, GetUsuariostelegram, usuarioSelecionado, setUsuarioSelecionado }}>
            {children}
        </TelegramUsuariosContext.Provider>
    )
}

export function useTelegramUsuarios() {
    const context = useContext(TelegramUsuariosContext);

    return context;
}
