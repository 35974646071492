import React, { useEffect } from 'react'
import {ContainerMenu, Menu, MobileController} from "./styles";
import GroupIcon from '@mui/icons-material/Group';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {useTabs} from "../../contexts/tabs.context";
import { useAuth } from '../../contexts/autenticacao.context';


export default function MenuLateral() {
    const { tabAtiva, setTabAtiva } = useTabs();
    const {role, setRole} = useAuth()

    useEffect(()=>{
        const roleId = localStorage.getItem('@RoleId');
        setRole(roleId)
    }, [])



    return (
        <>
            <MobileController>
                <ContainerMenu>


                    {role <= 2 &&
                        <Menu onClick={() => setTabAtiva("TelegramUsuarios")} ativo={tabAtiva === "TelegramUsuarios" || (tabAtiva == "" && role < 4)}>
                            <GroupIcon/>
                            <p>Clientes</p>
                        </Menu>
                    }

                    {role <= 4 &&
                        <Menu onClick={() => setTabAtiva("Vendas")} ativo={tabAtiva === "Vendas" || (tabAtiva == "" && role == 4)}>
                            <ShoppingBasketIcon/>
                            <p>Vendas</p>
                        </Menu>
                    }



                    {role <= 2 &&
                    <Menu onClick={() => setTabAtiva("UsuariosInternos")} ativo={tabAtiva === "UsuariosInternos"}>
                        <PeopleAltIcon/>
                        <p>Usuarios Internos</p>
                    </Menu>
                    }
                    {role <= 2 &&
                    <Menu onClick={() => setTabAtiva("CadastroDeProdutos")} ativo={tabAtiva === "CadastroDeProdutos"}>
                        <AddShoppingCartIcon/>
                        <p>Cadastro de Produtos</p>
                    </Menu>
                    }
                    {role <= 2 &&
                    <Menu onClick={() => setTabAtiva("CadastroDeGrupos")} ativo={tabAtiva === "CadastroDeGrupos"}>
                        <CreateNewFolderIcon/>
                        <p>Cadastro de Grupos</p>
                    </Menu>
                    }
                    {role <= 2 &&
                    <Menu onClick={() => setTabAtiva("Whitelist")} ativo={tabAtiva === "Whitelist"}>
                        <PlaylistAddIcon/>
                        <p>Whitelist</p>
                    </Menu>
                    }
                    {role <= 2 &&
                      <Menu onClick={() => setTabAtiva("HotmartEdit")} ativo={tabAtiva === "HotmartEdit"}>
                          <PlaylistAddIcon/>
                          <p>Hotmart Edit</p>
                      </Menu>
                    }
                    {role <= 2 &&
                      <Menu onClick={() => setTabAtiva("Exportacoes")} ativo={tabAtiva === "Exportacoes"}>
                          <PlaylistAddIcon/>
                          <p>Exportações</p>
                      </Menu>
                    }
                </ContainerMenu>
            </MobileController>
        </>
    )
}
