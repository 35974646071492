import styled from 'styled-components';
import FundoSobrevivente from '../../../assets/fundo-osobrevivente.jpg';
import FundoSobreviventeMobile from '../../../assets/fundo-login-mobile.png';

export const Paineis = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100vh;
  background-image: url('${FundoSobrevivente}');
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: 1023px) {
    background-image: url('${FundoSobreviventeMobile}');
    display: flex;
    height: ${(props) => (props.register ? '100%' : '100vh')};

    justify-content: center;
    .jogadorImg {
      display: none;
    }
  }
`;
export const PainelDireito = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00152d90;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 1023px) {
    background: none;
    box-shadow: none;
    .Logo {
      width: 60%;
      height: 60%;
      align-self: center;
      margin: 10vh 0 5vh 0;
    }
  }
`;
export const ConteudoDireito = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const AlinharCentro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const PainelEsquerdo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;
