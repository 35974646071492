import instance from './instance';

class HotmartService {
  ProcessarHotmart(id) {
    return instance.post(`api/LineraPay/registroDeCompraHotmartManual`, {
      codigoDaTransacao: id
    });
  }
}

export default new HotmartService();
