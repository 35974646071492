import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    ReactElement,
} from 'react';

import instance from '../services/instance';
import {useAuth} from "./autenticacao.context";
import {useLoading} from "./loading.context";

const ProdutosClienteContext = createContext({});

export const ProdutosClienteProvider = ({ children }) => {
    const {user} = useAuth();
    const {setLoadingAtivo} = useLoading();

    const [produtosCliente, setProdutosCliente] = useState(null);
    const [produtoSelecionado, setProdutoSelecionado] = useState(null)
    const [produtoDeletarSelecionado, setProdutoDeletarSelecionado] = useState(null)
    const [gruposVinculados, setGruposVinculados] = useState([])
    const [gruposDisponiveis, setGruposDisponiveis] = useState([])
    const [idProdutoIntegracaoEditando, setIdProdutoIntegracaoEditando] = useState(null);

    let plataforma = {
        Hotmart: 1,
        Monetizze: 5
    }

    const GetProdutosCliente = async () => {
        setLoadingAtivo(true)
        await instance.get("api/LineraPay/exibirProd", { params: { ClienteId: user.ClientId } })
            .then(r => {
                setProdutosCliente(r.data)
                setLoadingAtivo(false)
            })
            .catch(e => {
                console.error(e)
                setLoadingAtivo(false)
            })
    }

    const AdicionarProduto = async (NomeProduto,  IntegracaoId_Interno_valor, valorPlataforma, Clear) => {
        setLoadingAtivo(true)
        await instance.post("/api/LineraPay/registrarProd", {
            ClienteId: user.ClientId,
            NomeProduto,
            IntegracaoId_Interno_valor,
            IntegracaoId: plataforma[valorPlataforma]
        })
        Clear();
        await GetProdutosCliente();
        setLoadingAtivo(false)
    }

    const EditarProduto = async () => {
        setLoadingAtivo(true)
        await instance.put("/api/LineraPay/editarProd", {
            ClienteId: user.ClientId,
            IdProdutoIntegracao: produtoSelecionado.idProdutoIntegracao,
            IntegracaoId: plataforma[produtoSelecionado.integracaoId],
            Ativo: produtoSelecionado.ativo,
            NomeProduto: produtoSelecionado.nomeProduto,
            IntegracaoId_Interno_valor: produtoSelecionado.integracaoIdInternoValor
        })
        setProdutoSelecionado(null)
        await GetProdutosCliente();
        setLoadingAtivo(false)
    }

    const DeletarProduto = async () => {
        setLoadingAtivo(true)
        await instance.delete("/api/LineraPay/deletarProd", {data: {
            ClienteId: user.ClientId,
            NomeProduto: produtoDeletarSelecionado.NomeProduto,
            IntegracaoId_Interno_valor: produtoDeletarSelecionado.IdProdutoHotmart
        }})
        setProdutoDeletarSelecionado(null)
        await GetProdutosCliente();
        setLoadingAtivo(false)
    }

    const ExibirGruposVinculados  = async (idProdutoIntegracao) => {
        setLoadingAtivo(true)
        await instance.get("/api/LineraPay/exibirGruposVinculados", {params: { idProdutoIntegracao }})
            .then(r => {
                setGruposVinculados(r.data)
            })
        await GetProdutosCliente();
        setLoadingAtivo(false)
    }

    const AssociarGrupos  = async (arrayGrupos) => {
        setLoadingAtivo(true)
        await instance.post("/api/LineraPay/vincularGrupoTelegram", {
            IdProdutoIntegracao: idProdutoIntegracaoEditando,
            IdGrupoTelegram: arrayGrupos
        })
        setIdProdutoIntegracaoEditando(null)
        await GetProdutosCliente();
        setLoadingAtivo(false)
    }

    const ExibirGruposDisponiveis  = async (idProdutoIntegracao) => {
        setLoadingAtivo(true)
        await instance.get("/api/LineraPay/exibirGruposDisponiveis",
            { params: {
                ClienteId: user.ClientId,
                idProdutoIntegracao
            }
            })
            .then(r => {
                setGruposDisponiveis(r.data)
            })
        await GetProdutosCliente();
        setLoadingAtivo(false)
    }

    return (
        <ProdutosClienteContext.Provider value={
            {
                produtosCliente,
                setProdutosCliente,
                produtoSelecionado,
                setProdutoSelecionado,
                GetProdutosCliente,
                AdicionarProduto,
                EditarProduto,
                DeletarProduto,
                produtoDeletarSelecionado,
                setProdutoDeletarSelecionado,
                gruposDisponiveis,
                gruposVinculados,
                setGruposVinculados,
                setGruposDisponiveis,
                idProdutoIntegracaoEditando,
                setIdProdutoIntegracaoEditando,
                ExibirGruposVinculados,
                ExibirGruposDisponiveis,
                AssociarGrupos
            }}>
            {children}
        </ProdutosClienteContext.Provider>
    );
};

export function useProdutosCliente() {
    const context = useContext(ProdutosClienteContext);

    return context;
}
