import React from 'react'
import TelegramGrupos from "./TelegramGrupos";
import { useTabs } from "../../contexts/tabs.context";
import TelegramUsuarios from "./TelegramUsuarios";
import Clientes from "./Clientes";
import Produtos from "./Produtos";
import Vendas from "./Vendas";
import UsuariosInternos from "./UsuariosInternos";
import CadastroDeProdutos from "./CadastroProdutos";
import CadastroGrupos from "./CadastroGrupos";
import Whitelist from "./Whitelist";
import { useAuth } from '../../contexts/autenticacao.context';
import HotmartEdit from "./HotmartEdit";
import Exportacoes from './Exportacoes';


export default function PainelPrincipal() {
    const { tabAtiva } = useTabs();
    const { role } = useAuth()

    if (tabAtiva === "TelegramUsuarios" || (tabAtiva == "" && role < 4))
        return (
            <>
                <div style={{ width: '100%', overflow: 'auto', paddingBottom: '80px' }}>
                    <TelegramUsuarios />
                </div>
            </>
        )

    if (tabAtiva === "Vendas" || (tabAtiva == "" && role == 4))
        return (
            <>
                <div style={{ width: '100%', overflow: 'auto', paddingBottom: '80px' }}>
                    <Vendas />
                </div>
            </>
        )

    if (tabAtiva === "CadastroDeProdutos")
        return (
            <>
                <div style={{ width: '100%', overflow: 'auto', paddingBottom: '80px' }}>
                    <CadastroDeProdutos />
                </div>
            </>
        )

    if (tabAtiva === "CadastroDeGrupos")
        return (
            <>
                <div style={{ width: '100%', overflow: 'auto', paddingBottom: '80px' }}>
                    <CadastroGrupos />
                </div>
            </>
        )

    if (tabAtiva === "UsuariosInternos")
        return (
            <>
                <div style={{ width: '100%', overflow: 'auto', paddingBottom: '80px' }}>
                    <UsuariosInternos />
                </div>
            </>
        )

    if (tabAtiva === "Whitelist")
        return (
            <>
                <div style={{ width: '100%', overflow: 'auto', paddingBottom: '80px' }}>
                    <Whitelist />
                </div>
            </>
        )

    if (tabAtiva === "HotmartEdit")
        return (
            <>
                <div style={{ width: '100%', overflow: 'auto', paddingBottom: '80px' }}>
                    <HotmartEdit />
                </div>
            </>
        )

    if (tabAtiva === "Exportacoes")
        return (
            <>
                <div style={{ width: '100%', overflow: 'auto', paddingBottom: '80px' }}>
                    <Exportacoes />
                </div>
            </>
        )

    else
        return (
            <>
                <div style={{ width: '100%', overflow: 'auto', paddingBottom: '80px' }}>
                    <TelegramUsuarios />
                </div>
            </>
        )
}
