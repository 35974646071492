import { createContext, useContext, useState } from 'react'

export const TabsContext = createContext({})

export const TabsProvider = ({ children }) => {
    const [tabAtiva, setTabAtiva] = useState("")

    return (
        <TabsContext.Provider value={{ tabAtiva, setTabAtiva }}>
            {children}
        </TabsContext.Provider>
    )
}

export function useTabs() {
    const context = useContext(TabsContext);

    return context;
}
