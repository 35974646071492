import styled from 'styled-components';
import FundoSobrevivente from '../../../assets/fundo-osobrevivente.jpg';
import FundoSobreviventeMobile from '../../../assets/fundo-login-mobile.png';

export const Paineis = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-color: #FFF;

  @media only screen and (max-width: 1023px) {
    background: #143182;
    display: flex;
    height: ${(props) => (props.register ? '100%' : '100vh')};

    justify-content: center;
    .jogadorImg {
      display: none;
    }
  }
`;
export const PainelDireito = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #143182;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: 1023px) {
        background: none;
        box-shadow: none;
        .Logo {
            width: 60%;
            height: 60%;
            align-self: center;
            margin: 10vh 0 5vh 0;
        }
    }
`;
export const ConteudoDireito = styled.div`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media only screen and (max-width: 1023px) {
    min-width: unset;
  }
`;
export const AlinharCentro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const LogoMobile = styled.img`
  width: 300px;

  @media only screen and (min-width: 1023px) {
    display: none;
  }
`

export const PainelEsquerdo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;
