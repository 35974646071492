import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuLateral from "../../../MenuLateral";
import {ContainerMenu, Menu} from "../../../MenuLateral/styles";
import GroupIcon from "@mui/icons-material/Group";
import {useTabs} from "../../../../contexts/tabs.context";
import MenuIcon from '@mui/icons-material/Menu';
import {MobileController} from "./styles";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

export default function DrawerMobile() {
    const [state, setState] = React.useState(false);
    const {tabAtiva, setTabAtiva} = useTabs();

    const list = () => (
        <Box
            sx={{ 'auto' : 250, height: '100%', overflow: 'unset' }}
            role="presentation"
            onClick={() => setState(false)}
            onKeyDown={() => setState(false)}
        >
            <ContainerMenu>
                <Menu onClick={() => setTabAtiva("TelegramUsuarios")} ativo={tabAtiva === "TelegramUsuarios"}>
                    <GroupIcon/>
                    <p>Clientes</p>
                </Menu>
                <Menu onClick={() => setTabAtiva("Vendas")} ativo={tabAtiva === "Vendas"}>
                    <ShoppingBasketIcon/>
                    <p>Vendas</p>
                </Menu>
                <Menu onClick={() => setTabAtiva("UsuariosInternos")} ativo={tabAtiva === "UsuariosInternos"}>
                    <PeopleAltIcon/>
                    <p>Usuarios Internos</p>
                </Menu>
                <Menu onClick={() => setTabAtiva("CadastroDeProdutos")} ativo={tabAtiva === "CadastroDeProdutos"}>
                    <AddShoppingCartIcon/>
                    <p>Cadastro de Produtos</p>
                </Menu>
                <Menu onClick={() => setTabAtiva("CadastroDeGrupos")} ativo={tabAtiva === "CadastroDeGrupos"}>
                    <CreateNewFolderIcon/>
                    <p>Cadastro de Grupos</p>
                </Menu>
                <Menu onClick={() => setTabAtiva("Whitelist")} ativo={tabAtiva === "Whitelist"}>
                    <PlaylistAddIcon/>
                    <p>Whitelist</p>
                </Menu>
                <Menu onClick={() => setTabAtiva("HotmartEdit")} ativo={tabAtiva === "HotmartEdit"}>
                    <PlaylistAddIcon/>
                    <p>Hotmart Edit</p>
                </Menu>
                <Menu onClick={() => setTabAtiva("Exportacoes")} ativo={tabAtiva === "Exportacoes"}>
                    <PlaylistAddIcon/>
                    <p>Exportações</p>
                </Menu>
            </ContainerMenu>
        </Box>
    );

    return (
        <>
            <MobileController>
                <Button onClick={() => setState(true)}><MenuIcon /></Button>
            </MobileController>
            <Drawer
                anchor="left"
                open={state}
                onClose={() => setState(false)}
            >
                {list()}
            </Drawer>
        </>
    );
}
