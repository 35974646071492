import styled from 'styled-components';
import FundoSobrevivente from '../../../assets/fundo-osobrevivente.jpg';
import FundoSobreviventeMobile from '../../../assets/fundo-login-mobile.png';

export const Paineis = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100vh;
  background-image: url('${FundoSobrevivente}');
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: 1023px) {
    background-image: url('${FundoSobreviventeMobile}');
    display: flex;
    height: 100%;

    justify-content: center;
    .jogadorImg {
      display: none;
    }
  }
`;
export const PainelDireito = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00152d90;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f120;
  }
  &::-webkit-scrollbar-thumb {
    background: #007bb250;
    border-radius: 25px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #6885A350;
  }
  &::-webkit-scrollbar {
    display: block;
  }
  @media only screen and (max-width: 1023px) {
    background: none;
    box-shadow: none;
    .Logo {
      width: 60%;
      height: 60%;
      align-self: center;
      margin: 10vh 0 5vh 0;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const ConteudoDireito = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const AlinharCentro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const PainelEsquerdo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const Container = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 500px;
  padding: 32px;
`
export const ModalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  @media(max-width: 500px){
    grid-template-columns: 1fr;
  }
`
export const ContainerCampo = styled.div`
  display: flex;
  flex-direction: column;
`
export const TituloModal = styled.div`
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #FFF;
`
export const LabelModal = styled.label`
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #FFF;
`
export const ValorFinal = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  text-transform: uppercase;
  color: #FFF;
  margin: 0;
`
export const DescontoPercentual = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #FFF;
  margin: 0;
  span{
    color: #F33C59;
  }
`
