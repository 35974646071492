import axios from "axios"
import { BotaoContained } from "../../StyledComponents";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useLoading } from "../../../contexts/loading.context";
import instance from "../../../services/instance";

export default function Exportacoes() {
    const { setLoadingAtivo } = useLoading();

    const getExportacoes = async () => {
        setLoadingAtivo(true);
        try {
            const response = await instance.get("/api/LineraPay/ExportarRelatorio", {
                responseType: 'blob' // Isso é importante para receber o arquivo como um blob
            });

            // Crie um URL temporário para o blob
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Crie um link temporário
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'relatorio.csv'); // Defina o nome do arquivo

            // Adicione o link ao documento e clique nele
            document.body.appendChild(link);
            link.click();

            // Remova o link do documento
            document.body.removeChild(link);
        } catch (e) {
            console.error(e);
        }
        setLoadingAtivo(false);
    }

    const getExportacoesRelatorioAnaliticoHot = async () => {
        setLoadingAtivo(true);
        try {
            const response = await instance.get("/api/LineraPay/ExportarRelatorioAnaliticoHot", {
                responseType: 'blob' // Isso é importante para receber o arquivo como um blob
            });

            // Crie um URL temporário para o blob
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Crie um link temporário
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'relatorio.csv'); // Defina o nome do arquivo

            // Adicione o link ao documento e clique nele
            document.body.appendChild(link);
            link.click();

            // Remova o link do documento
            document.body.removeChild(link);
        } catch (e) {
            console.error(e);
        }
        setLoadingAtivo(false);
    }

    return(
        <div style={{
            padding: '20px',
            display: 'flex',
            gap: '20px',
        }}>
            <BotaoContained onClick={getExportacoes}><InsertDriveFileIcon />&zwnj; Exportação Completa</BotaoContained>
            <BotaoContained onClick={getExportacoesRelatorioAnaliticoHot}><InsertDriveFileIcon />&zwnj; Relatório Analítico Hotmart</BotaoContained>
        </div>
    )
}