import { createTheme, ThemeProvider } from '@mui/material/styles';
import { borderBottom } from '@mui/system';

export const theme = createTheme({
  typography: {
    "fontFamily": "Roboto",
    body1: {
      color: "#FFF"
    }
  },
  palette: {
    white: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#000',
    },
    primary: {
      main: '#000',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#0044ff',
    },
    tonalOffset: 0.2,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root:{
          height: '52px',
          borderRadius: '26px',
          color: '#143182',
          transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
          '&:hover': {
            boxShadow: '0 6px 12px 0 rgba(0,0,0,0.16)',
          },
          "&.Mui-focused fieldset": {
            borderColor: "#0044ff",
          },
        },
        notchedOutline: {
          transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
          borderColor: '#B0B5C1', // cor padrão da borda
          '&:hover': {
            borderColor: '#0044ff',
          },
          '&.Mui-focused': {
            borderColor: '#0044ff', // cor da borda quando o input está ativo
          },
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#22212c'
        }
      }
    },
    MuiTextField: {
      styleOverrides:{
        root: {
          borderRadius: '26px',
          height: '52px',
          transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
          '&:hover': {
            boxShadow: '0 6px 12px 0 rgba(0,0,0,0.16)',
          },
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: '1px solid #B0B5C1',
          height: '52px',
          borderRadius: '26px',
          backgroundColor: '#FFFFFF',
          color: '#231F20',
          "&.MuiOutlinedInput-root": {
            "& fieldset": {
              color: "red"
            },
            "&:hover fieldset": {
              color: "yellow"
            },
            "&.Mui-focused fieldset": {
              color: "green"
            }
          }
        },
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#000'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF00",
          boxShadow: "unset",
        }
      }
    },
    MuiPaper: {
      styleOverrides:{
        root: {
          backgroundColor: '#FFFFFF',
          boxShadow: 'unset',
        }
      }
    },
    MuiList: {
      styleOverrides:{
        root: {
          backgroundColor: '#2D455F',
          borderRadius: '8px',
          border: "1px solid #435B75",
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root:{
          backgroundColor: 'rgba(45, 69, 95, 0.8);',
          borderRadius: '25px',
          color: '#00B1FF',
          "&.Mui-selected": {
            backgroundColor: "#00B1FF",
            color: "#FFF",
            borderRadius: "25px",
            borderBottom: "unset"
          },
        },
      }
    },
    MuiTabs:{
      styleOverrides:{
        root:{
          display: 'flex',
          gap: '12px'
        }
      }
    },
    MuiMenuItem:{
      styleOverrides:{
        root:{
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          padding: '12px'
        }
      }
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          '&:nth-child(odd)': {
            backgroundColor: '#00000010'
          }
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          borderRadius: '12px'
        }
      }
    }
  }
});
