import React from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Typography,
    InputAdornment,
    IconButton,
    OutlinedInput,
    Modal,
} from '@mui/material';
import logo from '../../../assets/logo.png';
import jogador1 from '../../../assets/jogador 1.png';
import {
    AlinharCentro,
    ConteudoDireito,
    Paineis,
    PainelDireito,
    PainelEsquerdo,
    BlockColumn,
    Label,
    ErrorMessage,
    ModalStyle,
} from './styles';
import { Key, Person, Visibility, VisibilityOff } from '@mui/icons-material';
import loginService from '../../../services/login.service';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const guid = window.location.search.split('=')[1];
    const [passwordForm, setPasswordForm] = React.useState({
        password: '',
        confirmedPassword: '',
    });

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setPasswordForm({
            ...passwordForm,
            [name]: value,
        });
    };
    const [values, setValues] = React.useState({
        amount: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        showConfirmedPassword: false,
        errorMessage: '',
        newPasswordLabel: 'Insira a nova senha:',
        registerComponent: false,
    });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleMouseDownPassword = (
        event
    ) => {
        event.preventDefault();
    };

    const submitHandle = (e) => {
        e.preventDefault();
        if(passwordForm.password === passwordForm.confirmedPassword)
            loginService
                .RecoveryPassword(guid, passwordForm.password)
                .then(() => {
                    handleOpen();
                })
                .catch(() => {});
    };
    return (
        <>
            <Paineis register={values.registerComponent}>
                <PainelEsquerdo>
                    <img src={logo} alt='Logo' className='Logo' />
                </PainelEsquerdo>
                <PainelDireito>
                    <form onSubmit={(e) => submitHandle(e)}>
                        <ConteudoDireito>
                            <>
                                <BlockColumn>
                                    <Label>Nova Senha</Label>
                                    <OutlinedInput
                                        required
                                        id='outlined-adornment-password'
                                        placeholder='****'
                                        type={values.showPassword ? 'text' : 'password'}
                                        onChange={(e) => handleInputChange(e)}
                                        name='password'
                                        startAdornment={
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                edge='end'
                                                color='white'
                                                style={{ marginRight: '4px' }}
                                            ></IconButton>
                                        }
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge='end'
                                                    color='white'
                                                >
                                                    {values.showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />{' '}
                                </BlockColumn>
                                <BlockColumn>
                                    <Label>Confirmar Senha</Label>
                                    <OutlinedInput
                                        required
                                        id='outlined-adornment-password'
                                        placeholder='****'
                                        type={values.showConfirmedPassword ? 'text' : 'password'}
                                        value={passwordForm.confirmedPassword}
                                        onChange={(e) => handleInputChange(e)}
                                        name='confirmedPassword'
                                        startAdornment={
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                edge='end'
                                                color='white'
                                                style={{ marginRight: '4px' }}
                                            ></IconButton>
                                        }
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge='end'
                                                    color='white'
                                                >
                                                    {values.showConfirmedPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </BlockColumn>
                                {passwordForm.confirmedPassword &&
                                    passwordForm.password !== passwordForm.confirmedPassword && (
                                        <ErrorMessage>Senhas não estão iguais.</ErrorMessage>
                                    )}
                            </>
                            <Modal open={open} onClose={handleClose}>
                                <ModalStyle>
                                    {'Senha redefinida com sucesso. '}{' '}
                                    <Button
                                        style={{ width: '10%', marginTop: '20px' }}
                                        variant='contained'
                                        onClick={() => navigate('/')}
                                    >
                                        Ok
                                    </Button>
                                </ModalStyle>
                            </Modal>
                            <div>
                                <Button
                                    style={{ width: '100%' }}
                                    variant='contained'
                                    type='submit'
                                >
                                    Enviar Nova Senha
                                </Button>
                            </div>
                        </ConteudoDireito>
                    </form>
                </PainelDireito>
            </Paineis>
        </>
    );
};

export default ResetPassword;
