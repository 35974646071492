import styled from 'styled-components'
import {Button} from "@mui/material";

export const BotaoContained = styled(Button)`
  color: #FFF !important;
  background: #1E4AC5 !important;
  border-radius: 4px !important;
  height: 40px !important;
  align-self: center;
  padding: 16px !important;
`

export const BotaoContainedSecundario = styled(Button)`
  background: #E5E9F2 !important;
  border-radius: 4px !important;
  height: 40px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: #6F768A !important;
  align-self: center !important;
  padding: 16px !important;
`

export const BotaoDanger = styled(Button)`
  color: #FFF !important;
`

export const H1Modal = styled.h1`
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #143183;
`
