import styled from 'styled-components';

export const MobileController = styled.div`
  display: none;
  @media only screen and (max-width: 1023px) {
    display: block;
  }
`

export const ContainerMenu = styled.div`
  background: #143183;
  border-radius: 0px;
  height: 100%;
  width: 240px;
  height: 100%;
  padding: 14px 0 14px 14px;
  
  h1 {
    margin: 0;
  }
`

export const Menu = styled.div`
  display: flex;
  gap: 4px;
  background: ${props => props.ativo ? "#EEF0F5" : "unset" };
  border-radius: 4px 0px 0px 4px;
  align-items: center;
  padding: 16px 10px;
  cursor: pointer;
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: ${props => props.ativo ? "#143183" : "#FFF" };
    margin: 0;
  }
  svg {
    color: ${props => props.ativo ? "#143183" : "#FFF" };
  }
`
