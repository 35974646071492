import {createContext, useContext, useEffect, useState} from 'react'
import instance from '../services/instance';
import {useTelegramUsuarios} from "./telegramUsuarios.context";
import {useAuth} from "./autenticacao.context";
import {useLoading} from "./loading.context";

export const ProdutosContext = createContext({})

export const ProdutosProvider = ({ children }) => {
    const [produtosUsuarioSelecionado, setProdutosUsuarioSelecionado] = useState();
    const [vendas, setVendas] = useState();
    const {user} = useAuth();
    const {setLoadingAtivo} = useLoading()
    const [linkRemoverAssociado, setLinkRemoverAssociado] = useState("");
    const [lastCliente, setLastCliente] = useState({
        hotmartId: '',
        userId: ''
    })
    const [linkBotAssociacao, setLinkBotAssociacao] = useState("");
    const [totalVendas, setTotalVendas] = useState(30);

    const GetUserProdutos = async (hotmartId, userId) => {
        setLoadingAtivo(true)
        setLastCliente({
            hotmartId,
            userId
        })
        let listaClientes = await instance.post("/api/LineraPay/verComprasUsuario", {
            Id_Hotmart_Usuario: hotmartId,
            ClientId: userId
        }).catch(() => setLoadingAtivo(false));
        setProdutosUsuarioSelecionado(listaClientes.data);
        setLoadingAtivo(false)
    }

    const GetVendas = async (page = 1, searchText = "") => {
        setLoadingAtivo(true)
        let listaClientes = await instance.post("/api/LineraPay/vendas", {clienteId: user.ClientId, NumeroDaPagina: page, FiltroTexto: searchText});
        setVendas(listaClientes.data.vendas);
        if(totalVendas === 30)
            setTotalVendas(listaClientes.data.totalVendas);
        setLoadingAtivo(false);
    }

    const RemoverTelefoneAssociado = async (HotMartCodigoInternoCompra) => {
        setLoadingAtivo(true)
        let link = await instance.post("/api/LineraPay/revogarTelefoneAssociadoDaCompra", {HotMartCodigoInternoCompra});
        setLinkRemoverAssociado(link.data)
        await GetUserProdutos(lastCliente.hotmartId, lastCliente.userId);
        setLoadingAtivo(false)
    }

    const GerarLinkBotAssociacao = async (link) => {
        console.log(link)
        setLinkBotAssociacao(link);
        await GetUserProdutos(lastCliente.hotmartId, lastCliente.userId);
    }

    return (
        <ProdutosContext.Provider value={{ produtosUsuarioSelecionado, setProdutosUsuarioSelecionado, totalVendas, GetUserProdutos, GetVendas, vendas, linkRemoverAssociado, setLinkRemoverAssociado, RemoverTelefoneAssociado, GerarLinkBotAssociacao, linkBotAssociacao, setLinkBotAssociacao }}>
            {children}
        </ProdutosContext.Provider>
    )
}

export function useProdutos() {
    const context = useContext(ProdutosContext);

    return context;
}
