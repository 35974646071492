import react, { useEffect, useState } from 'react';
import { Box, Button, Modal, TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React from "react";
import { useTelegramGrupos } from "../../../contexts/telegramGrupos.context";
import CloseIcon from '@mui/icons-material/Close';
import { useTelegramUsuarios } from "../../../contexts/telegramUsuarios.context";
import { useProdutos } from "../../../contexts/produtos.context";
import { ContainerCopyPaste } from "../ModalTabela/style";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function Vendas({ open, onClose, data }) {
    const { GetGruposUsuario, gruposUsuario, convidarProGrupo, removerDoGrupo, linkGrupo, setLinkGrupo } = useTelegramGrupos();
    const [exibirDetalhesCompra, setExibirDetalhesCompra] = useState(false);
    const { GetUserProdutos, produtosUsuarioSelecionado, vendas, totalVendas, GetVendas, setLinkRemoverAssociado, linkRemoverAssociado, RemoverTelefoneAssociado, GerarLinkBotAssociacao, linkBotAssociacao, setLinkBotAssociacao } = useProdutos();
    const options = {
        filterType: 'checkbox',
        selectableRows: false,
        filter: false,
        viewColumns: false,
        responsive: 'simple'
    };

    const optionsVenda = {
        serverSide: true,
        filterType: 'checkbox',
        selectableRows: false,
        filter: false,
        viewColumns: false,
        responsive: 'simple',
        columnOrder: [0, 1, 11, 3, 6, 2, 4, 5, 9, 7, 8, 10, 12],
        onTableChange: (action, tableState) => {
            if (action === 'changePage' || action === 'search') {
                console.log(tableState.searchText)
                GetVendas(tableState.page + 1, tableState.searchText);
            }
        },
        customSearch: (searchQuery, currentRow, columns) => {
            return true;
        },
        rowsPerPage: 30,
        count: totalVendas,
    };

    const HandleExibirGrupos = (Id_Hotmart_Usuario) => {
        setExibirDetalhesCompra(true);
        GetGruposUsuario(Id_Hotmart_Usuario);
    }

    useEffect(() => {
        GetVendas();
    }, [])

    const columnAcoes = [
        {
            name: "Id Hotmart Usuario Compra",
            options: {
                display: false
            }
        },
        "Cód. Ass",
        {
            name: "Nome",
            options: {
                display: false
            }
        },
        "Nome Produto",
        "Telefone",
        "Data Compra",
        "Status Compra",
        "Email",
        {
            name: "TelegramID Boolean",
            options: {
                display: false,
            }
        },
        {
            name: "Link Telegram",
            options: {
                display: false
            }
        },
        {
            name: "Link Desabilitado",
            options: {
                display: false
            }
        },
        "Cód Compra",
        {
            label: "Ações",
            filter: false,
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                <Button onClick={() => HandleExibirGrupos(tableMeta.rowData[1])}>
                                    Ver Detalhes
                                </Button>
                                {tableMeta.rowData[8] === "true" &&
                                    <Button style={{ textAlign: "left" }} onClick={() => RemoverTelefoneAssociado(tableMeta.rowData[1])}>
                                        Remover Telefone Associado
                                    </Button>
                                }
                                {tableMeta.rowData[10] === "True" &&
                                    <Button style={{ textAlign: "left" }} onClick={() => GerarLinkBotAssociacao(tableMeta.rowData[9])}>
                                        Gerar Link Bot Associação
                                    </Button>
                                }
                            </div>
                        </>
                    )
                }
            }
        }
    ]

    const columnGrupos = [
        {
            name: "Id telegram Usuario",
            options: {
                display: false
            }
        },
        {
            name: "Telefone",
            options: {
                display: false
            }
        },
        "Telegram Id",
        {
            name: "Hotmart Id",
            options: {
                display: false
            }
        },
        {
            name: "Username",
            options: {
                display: false
            }
        },
        {
            name: "Nome",
            options: {
                display: false
            }
        },
        {
            name: "Sobrenome",
            options: {
                display: false
            }
        },
        {
            name: "Id Telegram Usuarios Grupo",
            options: {
                display: false
            }
        },
        {
            name: "Id Telegram Produto",
            options: {
                display: false
            }
        },
        {
            name: "Link Grupo",
            options: {
                display: false
            }
        },
        "Data Hora",
        "Nome do Grupo",
        {
            label: "Ações",
            filter: false,
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Button onClick={() => tableMeta.rowData[2] !== "" ? removerDoGrupo(tableMeta.rowData[2], tableMeta.rowData[12], tableMeta.rowData[0]) : convidarProGrupo(tableMeta.rowData[0], tableMeta.rowData[12], tableMeta.rowData[8])}>
                            {tableMeta.rowData[2] !== "" ? "Expulsar" : "Gerar Link"}
                        </Button>
                    )
                }
            }
        }
    ]

    return (
        <>
            <div style={{ width: '100%' }}>
                <MUIDataTable
                    title={"Vendas"}
                    data={vendas}
                    columns={columnAcoes}
                    options={optionsVenda}
                />
            </div>

            <Modal
                onClose={() => setExibirDetalhesCompra(false)}
                open={exibirDetalhesCompra}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <MUIDataTable
                        title={"Grupos"}
                        data={gruposUsuario}
                        columns={columnGrupos}
                        options={options}
                    />
                </Box>
            </Modal>

            {linkGrupo &&
                <Modal
                    onClose={() => setLinkGrupo("")}
                    open={linkGrupo != ""}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <ContainerCopyPaste>
                            <TextField variant="standard" value={linkGrupo} />
                            <Button variant="contained" onClick={() => navigator.clipboard.writeText(linkGrupo)} variant="outlined" startIcon={<ContentCopyIcon />}>
                                Copiar
                            </Button>
                        </ContainerCopyPaste>
                    </Box>
                </Modal>
            }

            {linkBotAssociacao &&
                <Modal
                    onClose={() => setLinkBotAssociacao("")}
                    open={linkBotAssociacao != ""}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <ContainerCopyPaste>
                            <TextField variant="standard" value={linkBotAssociacao} />
                            <Button variant="contained" onClick={() => navigator.clipboard.writeText(linkBotAssociacao)} variant="outlined" startIcon={<ContentCopyIcon />}>
                                Copiar
                            </Button>
                        </ContainerCopyPaste>
                    </Box>
                </Modal>
            }

            <Modal
                onClose={() => setLinkRemoverAssociado("")}
                open={linkRemoverAssociado != ""}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ContainerCopyPaste>
                        <TextField variant="standard" value={linkRemoverAssociado} />
                        <Button variant="contained" onClick={() => navigator.clipboard.writeText(linkRemoverAssociado)} variant="outlined" startIcon={<ContentCopyIcon />}>
                            Copiar
                        </Button>
                    </ContainerCopyPaste>
                </Box>
            </Modal>
        </>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
