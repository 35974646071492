import React from 'react'
import styles from './styles.module.css'
import MenuLateral from "../MenuLateral";
import PainelPrincipal from "../PainelPrincipal";
import {ContainerDrawerLogo, ContainerPainel, TopBar} from "./styles";
import LineraPayLogo from '../../assets/LineraPayLogo.png'
import AccountMenu from "./MenuUsuario";
import DrawerMobile from "./MenuUsuario/MenuMobile";


export default function PainelDeControle() {
    return (
        <>
            <ContainerPainel>
                <TopBar>
                    <ContainerDrawerLogo>
                        <DrawerMobile />
                        <img style={{
                            width: '200px',
                            height: '100%'
                        }} src={LineraPayLogo} />
                    </ContainerDrawerLogo>
                    <AccountMenu />
                </TopBar>
                <div style={{display: "flex", height: '100%'}}>
                    <MenuLateral />
                    <PainelPrincipal />
                </div>
            </ContainerPainel>
        </>
    )
}
