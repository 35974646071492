import styled from "styled-components";

import FundoSobrevivente from "../../../assets/fundo-osobrevivente.jpg";
import FundoSobreviventeMobile from "../../../assets/fundo-login-mobile.png";

export const Wrap = styled.div`
  width: 100vw;
  height: 100%;
  padding: 0 10%;
  @media only screen and (max-width: 1023px) {
    padding: 0 15%;
  }
`;
export const ErrorMessage = styled.div`
  color: red;
  margin-top: 5px;
  font-size: 0.7em;
`;

export const Select = styled.select`
  display: flex;
  flex-direction: row;
  padding-left: ${(props) => (props.ddi ? "10px" : "20px")};
  height: 52px;
  width: ${(props) => (props.ddi ? "20%" : "100%")};
  margin-bottom: 16px;
  background: rgba(45, 69, 95, 0.8);
  border: 1px solid #6885a3;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  color: #ffffff;
`;
// export const PainelDireito = styled.div`
//   display: flex;

//   background: #00152d90;
//   box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);

//   @media only screen and (max-width: 1023px) {
//     background: none;
//     box-shadow: none;
//     align-items: center;
//     justify-content: center;
//   }
// `;
export const BlockColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 18px;
`;
export const BlockRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  span {
    width: 12px;
  }
`;
export const RegisterWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  .Logo {
    width: 160px;
    height: 154px;
    align-self: center;
    margin: 1vh 0 1vh 0;
  }
  @media only screen and (max-width: 1023px) {
    background: none;
    box-shadow: none;
    width: 70vw;

    .Logo {
      width: 128px;
      height: 123px;
      align-self: center;
      margin: 1vh 0 1vh 0;
    }
  }
`;
export const Label = styled.div`
  display: flex;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #c9d5e1;
  margin-bottom: 8px;
  color: #ffffff;
`;
export const Title = styled.div`
  display: flex;
  align-self: flex-start;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 12px;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  align-self: center;
  margin-top: 8px;
  margin-bottom: 20px;
`;
export const Bar = styled.div`
  width: 100%;
  height: 8px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  margin-top: 20px;
  div {
    height: 8px;
    background: #2bd299;
    border-radius: 26px;
  }
`;

export const Paineis = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100vh;
  background-image: url("${FundoSobrevivente}");
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: 1023px) {
    background-image: url("${FundoSobreviventeMobile}");
    display: flex;
    justify-content: center;
    .jogadorImg {
      display: none;
    }
  }
`;
export const PainelDireito = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00152d90;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 1023px) {
    background: none;
    box-shadow: none;
    .Logo {
      width: 60%;
      height: 60%;
      align-self: center;
      margin: 15vh 0 5vh 0;
    }
  }
`;
export const ConteudoDireito = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const Value = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  width: fit-content;
  color: #ffffff;
`;
export const Discount = styled.div`
  display: flex;
  flex-direction: row;
  color: #6885a3;
  font-family: "Montserrat";
  font-style: normal;
  margin-right: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  span {
    color: #f33c59;
  }
`;
export const AlinharCentro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const PainelEsquerdo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  span {
    width: 12px;
  }
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
`;
export const Checkbox = styled.input`
  display: flex;
  width: 20px;
  height: 52px;
  background: rgba(45, 69, 95, 0.8);
  border: 1px solid #6885a3;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  box-sizing: border-box;
  color: #ffffff;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 16px;
  margin-right: 20px;
  ::placeholder {
    color: #6885a3;
  }
  span {
    color: #00b1ff;
    margin: 0;
    padding: 0;
  }
`;
export const ModalStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  color: #fff;
  background: rgba(45, 69, 95, 1);
  border: 1px solid #6885a3;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  padding: 21px;
  overflow: scroll;
`;

export const ModalStyle2 = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  width: 90vw;
  height: 95vh;
  background: rgba(45, 69, 95, 0.9);
  border: 1px solid #6885a3;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  padding: 21px;
  @media only screen and (min-width: 1023px) {
    width: 110vw;
    height: 110vh;
  }
`;

export const TitleModal = styled.div`
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  font-size: 28px;
  line-height: 120%;
  align-self: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 20px;
  margin-top: 40px;
  padding: 0 30px 0 30px;

  span {
    color: #2bd299;
  }
`;
export const TextPayment = styled.div`
  display: flex;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-bottom: 28px; ;
`;

export const Input = styled.input`
  display: flex;
  width: 100%;
  height: 52px;
  background: rgba(45, 69, 95, 0.8);
  border: 1px solid #6885a3;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  box-sizing: border-box;
  color: #ffffff;
  padding-left: 20px;
  margin-bottom: 16px;
  ::placeholder {
    color: #6885a3;
  }
  .checkbox {
    width: 10%;
  }
  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
