import React from 'react';
import {
    Button,
    Typography,
    InputAdornment,
    IconButton,
    OutlinedInput,
} from '@mui/material';
import logo from '../../../assets/logo.png';
import jogador1 from '../../../assets/jogador 1.png';
import {
    AlinharCentro,
    ConteudoDireito, LogoMobile,
    Paineis,
    PainelDireito,
    PainelEsquerdo,
} from './styles';
import { Key, Person, Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../../../contexts/autenticacao.context';
import loginService from '../../../services/login.service';
import { ErrorMessage } from '../../../components/styles';
import {useNavigate} from "react-router-dom";

const LoginPage = () => {
    let navigate = useNavigate();
    const { Login } = useAuth();
    const [forgotPassword, setForgotPassword] = React.useState(false);
    const [loginForm, setLoginForm] = React.useState({
        username: '',
        password: '',
        manterConectado: true,
    });

    const [emailRecuperacao, setEmailRecuperacao] = React.useState('')

    const handleEmailRecuperacao = (event) => {
        setEmailRecuperacao(event.target.value)
    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setLoginForm({
            ...loginForm,
            [name]: value,
        });
    };

    const [values, setValues] = React.useState({
        amount: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        errorMessage: '',
        forgotPasswordLabel: 'Insira o e-mail cadastrado:',
        registerComponent: false,
    });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    console.log(values.registerComponent);
    const handleMouseDownPassword = (
        event
    ) => {
        event.preventDefault();
    };

    const submitHandle = (e) => {
        e.preventDefault();

        !forgotPassword
            ? Login({
                login: loginForm.username,
                senha: loginForm.password,
            }).catch((err) => {
                setValues({...values, errorMessage: err.response.data.message})
            })
            : loginService
                .ResetPassword(emailRecuperacao)
                .then((response) => {
                    response &&
                    setValues({
                        ...values,
                        forgotPasswordLabel:
                            'Aguarde alguns minutos e verifique seu e-mail.',
                    });
                })
                .catch((err) => {
                    setValues({ ...values, errorMessage: err.response.data.message });
                });
    };
    return (
        <>
            <Paineis register={values.registerComponent}>
                <PainelEsquerdo>
                    <img src={logo} alt='Logo' className='Logo' style={{width: '80%'}} />
                </PainelEsquerdo>
                <PainelDireito>
                    <form onSubmit={(e) => submitHandle(e)}>
                        <ConteudoDireito>
                            {!forgotPassword ? (
                                <>
                                    <LogoMobile src={logo} alt='Logo' />
                                    <OutlinedInput
                                        required
                                        id='outlined-required'
                                        placeholder='Username'
                                        name='username'
                                        onChange={(e) => handleInputChange(e)}
                                        startAdornment={
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                edge='end'
                                                color='white'
                                                style={{ marginRight: '4px' }}
                                            >
                                                <Person />
                                            </IconButton>
                                        }
                                    />
                                    <OutlinedInput
                                        required
                                        id='outlined-adornment-password'
                                        placeholder='****'
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={loginForm.password}
                                        onChange={(e) => handleInputChange(e)}
                                        name='password'
                                        startAdornment={
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                edge='end'
                                                color='white'
                                                style={{ marginRight: '4px' }}
                                            >
                                                <Key />
                                            </IconButton>
                                        }
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge='end'
                                                    color='white'
                                                >
                                                    {values.showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <Typography>{values.forgotPasswordLabel}</Typography>
                                    <OutlinedInput
                                        required
                                        id='outlined-required'
                                        placeholder='E-mail'
                                        name='emailRecuperacao'
                                        onChange={(e) => handleEmailRecuperacao(e)}
                                        startAdornment={
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                edge='end'
                                                color='white'
                                                style={{ marginRight: '4px' }}
                                            >
                                                <Person />
                                            </IconButton>
                                        }
                                    />
                                    {values.errorMessage && (
                                        <ErrorMessage>{values.errorMessage}</ErrorMessage>
                                    )}
                                </>
                            )}
                            {(values.errorMessage && !forgotPassword) && (
                                <ErrorMessage>{values.errorMessage}</ErrorMessage>
                            )}
                            <div>
                                {/*<FormControlLabel*/}
                                {/*  name='manterConectado'*/}
                                {/*  control={*/}
                                {/*    <Checkbox*/}
                                {/*      defaultChecked*/}
                                {/*      onChange={(e) => handleInputChange(e)}*/}
                                {/*    />*/}
                                {/*  }*/}
                                {/*  label='Manter-me logado '*/}
                                {/*/>*/}
                                {/*{!forgotPassword ?*/}
                                {/*    <Button*/}
                                {/*        onClick={() => {*/}
                                {/*            navigate('/ResetPassword')*/}
                                {/*        }}*/}
                                {/*        variant='text'*/}
                                {/*    >*/}
                                {/*        Esqueci a senha*/}
                                {/*    </Button>*/}
                                {/*    :*/}
                                {/*    <Button*/}
                                {/*        onClick={() => {*/}
                                {/*            setLoginForm({...loginForm, password: ''})*/}
                                {/*        }}*/}
                                {/*        variant='text'*/}
                                {/*    >*/}
                                {/*        Voltar*/}
                                {/*    </Button>}*/}
                            </div>
                            <div>
                                <Button
                                    style={{ width: '100%', background: '#FFF' }}
                                    variant='contained'
                                    type='submit'
                                >
                                    {!forgotPassword ? ' Login' : 'Enviar'}
                                </Button>
                                {/*<AlinharCentro>*/}
                                {/*    <Typography>*/}
                                {/*        Não tem uma conta?{' '}*/}
                                {/*        <Button*/}
                                {/*            onClick={() => {*/}
                                {/*                navigate('/Register')*/}
                                {/*            }}*/}
                                {/*            variant='text'*/}
                                {/*        >*/}
                                {/*            Criar uma Conta*/}
                                {/*        </Button>*/}
                                {/*    </Typography>*/}
                                {/*</AlinharCentro>*/}
                            </div>
                        </ConteudoDireito>
                    </form>
                </PainelDireito>
            </Paineis>
        </>
    );
};

export default LoginPage;
