import react from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import {useLoading} from "../../contexts/loading.context";
import {ContainerLoading} from "./styles";

export default function Loading () {
    const {setLoadingAtivo, loadingAtivo} = useLoading()

    if(loadingAtivo)
        return <ContainerLoading>
            <Player
                autoplay
                loop
                src="https://assets5.lottiefiles.com/temp/lf20_LJK4oD.json"
                style={{ height: '300px', width: '300px' }}
            >
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
        </ContainerLoading>

    return null
}
