import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    ReactElement,
} from 'react';

import instance from '../services/instance';
import {useAuth} from "./autenticacao.context";
import {useLoading} from "./loading.context";

const GruposClienteContext = createContext({});

export const GruposClienteProvider = ({ children }) => {
    const {user} = useAuth();
    const {setLoadingAtivo} = useLoading();

    const [gruposCliente, setGruposCliente] = useState(null);
    const [grupoSelecionado, setGrupoSelecionado] = useState(null)
    const [grupoDeletarSelecionado, setGrupoDeletadoSelecionado] = useState(null)

    const GetGruposCliente = async () => {
        setLoadingAtivo(true)
        await instance.get("api/LineraPay/exibirGruposTelegram", { params: { ClienteId: user.ClientId } })
            .then(r => {
                setGruposCliente(r.data)
                setLoadingAtivo(false)
            })
            .catch(e => {
                console.error(e)
                setLoadingAtivo(false)
            })
    }

    const AdicionarGrupo = async (TelegramId, NomeGrupoTelegram) => {
        setLoadingAtivo(true)
        await instance.post("/api/LineraPay/registrarGrupoTelegram", {
            ClienteId: user.ClientId,
            TelegramId,
            NomeGrupoTelegram
        })
        await GetGruposCliente();
        setLoadingAtivo(false)
    }

    const EditarGrupo = async () => {
        setLoadingAtivo(true)
        await instance.put("/api/LineraPay/editarGrupoTelegram", {
            ClienteId: user.ClientId,
            IdGrupoTelegram: grupoSelecionado.idGrupoTelegram,
            TelegramId: grupoSelecionado.telegramId,
            NomeGrupoTelegram: grupoSelecionado.nomeGrupoTelegram,
            ProdutoIntegracaoId: grupoSelecionado.produtoIntegracaoId
        })
        setGrupoSelecionado(null)
        await GetGruposCliente();
        setLoadingAtivo(false)
    }

    const DeletarGrupo = async () => {
        setLoadingAtivo(true)
        await instance.delete("/api/LineraPay/deletarGrupoTelegram", grupoDeletarSelecionado)
        setGrupoDeletadoSelecionado(null)
        await GetGruposCliente();
        setLoadingAtivo(false)
    }

    return (
        <GruposClienteContext.Provider value={
            {
                gruposCliente,
                setGruposCliente,
                grupoSelecionado,
                setGrupoSelecionado,
                GetGruposCliente,
                AdicionarGrupo,
                EditarGrupo,
                DeletarGrupo,
                grupoDeletarSelecionado,
                setGrupoDeletadoSelecionado
            }}>
            {children}
        </GruposClienteContext.Provider>
    );
};

export function useGruposCliente() {
    const context = useContext(GruposClienteContext);

    return context;
}
