import styled from 'styled-components';
import {
    Box
} from '@mui/material';

export const Form = styled.form`

`

export const ModalBox = styled(Box)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0;
    min-width: 400px;
    overflow: hidden;
`
