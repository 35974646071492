import styled from 'styled-components';
import HeaderSmallBG from '../assets/landingpage_headerBG_small.png';
import HeaderBigBG from '../assets/landingpage_headerBG_big.png';
import BgSmall from '../assets/landingpage_BG_small.png';
import BgBig from '../assets/landingpage_BG_big.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('${HeaderSmallBG}');
  background-size: cover;
  min-width: 100%;
  height: 130vh;
  a {
    color: #ffffff;
    text-decoration: none;
  }
  img {
    margin-top: 74px;
    width: 160px;
    height: 154px;
  }
  @media only screen and (min-width: 1023px) {
    background-image: url('${HeaderBigBG}');

    img {
      margin-top: 117px;
      width: 240px;
      height: 230px;
    }
  }
`;

export const Title = styled.div`
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-size: ${(props) => (props.content ? '24px' : '28px')};
  line-height: 120%;
  text-align: ${(props) => (props.content ? 'left' : 'center ')};
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 20px;
  margin-top: ${(props) => (props.content ? '40px' : '62px')};
  padding: 0 30px 0 30px;

  span {
    color: #2bd299;
  }
`;
export const Text = styled.div`
  display: flex;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: ${(props) => (props.content ? 'left' : 'center ')};
  color: #ffffff;
  margin-bottom: 28px;
  margin-top: ${(props) => (props.content ? '-5px' : '0')};

  padding: ${(props) => (props.content ? '0 0 0 9px' : ' 0 32px 0 32px')};
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -100px;
  background-image: url('${BgSmall}');

  @media only screen and (min-width: 1023px) {
    background-image: url('${BgBig}');
    flex-direction: row;
    justify-content: center;
    section {
      width: 40vw;
    }
  }
`;
export const ItemContainter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 32px 0 32px;

  img {
    width: 21px;
    height: 21px;
  }
`;
export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #192d42;
  margin: 0 16px 0 16px;
  padding: 32px 16px 32px 16px;
`;
export const RegisterTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 26px;
`;
export const RegisterSubTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #c9d5e1;
  margin-bottom: 32px;
`;
export const FieldContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ModalStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  color: #fff;
  background: rgba(45, 69, 95, 1);
  border: 1px solid #6885a3;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  padding: 21px;
`;
export const FieldContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    width: 12px;
  }
`;
export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  span {
    width: 12px;
  }
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
`;

export const Select = styled.select`
  display: flex;
  flex-direction: row;
  padding-left: ${(props) => (props.ddi ? '10px' : '20px')};
  height: 52px;
  width: ${(props) => (props.ddi ? '20%' : '100%')};
  margin-bottom: 16px;
  background: rgba(45, 69, 95, 0.8);
  border: 1px solid #6885a3;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  color: #ffffff;
`;

export const RegisterWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Label = styled.div`
  display: flex;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #c9d5e1;
  margin-bottom: 8px;
  color: #ffffff;
`;
export const Input = styled.input`
  display: flex;
  width: 100%;
  height: 52px;
  background: rgba(45, 69, 95, 0.8);
  border: 1px solid #6885a3;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  box-sizing: border-box;
  color: #ffffff;
  padding-left: 20px;
  margin-bottom: 16px;
  ::placeholder {
    color: #6885a3;
  }
  .checkbox {
    width: 10%;
  }
  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
`;
export const Checkbox = styled.input`
  display: flex;
  width: 20px;
  height: 52px;
  background: rgba(45, 69, 95, 0.8);
  border: 1px solid #6885a3;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  box-sizing: border-box;
  color: #ffffff;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 16px;
  margin-right: 20px;
  ::placeholder {
    color: #6885a3;
  }
  span {
    color: #00b1ff;
    margin: 0;
    padding: 0;
  }
`;
export const Button = styled.button`
  display: flex;
  width: 100%;
  height: 52px;
  align-items: center;
  justify-content: center;
  background: #00b1ff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  border: none;
  margin: 32px 0 20px 0;
  cursor: pointer;
  ${(props) =>
    props.redButton &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    background: #da2428;
    box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.2);
    border-radius: 37px;
    cursor: pointer;
    width: 296px;
    height: 56px;
    margin: 0 32px 118px 32px;
  `}
`;
export const Value = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  width: fit-content;
  color: #ffffff;
`;
export const Discount = styled.div`
  display: flex;
  flex-direction: row;
  color: #6885a3;
  font-family: 'Montserrat';
  font-style: normal;
  margin-right: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  span {
    color: #f33c59;
  }
`;
export const StepBar = styled.div`
  display: flex;
  align-items: center;
`;
export const Step = styled.div`
  display: flex;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #767c82;
  background: #1b2127;
  .titleFooter {
    margin-bottom: 13px;
    align-self: flex-start;
    padding: 32px 32px 0 32px;
  }
  .line {
    height: 1px;
    width: 95vw;
    background: #767c82;
    margin-top: 21px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
`;

export const FooterInfo = styled.div`
  display: flex;
  font-family: 'Montserrat';
  padding: 0 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #767c82;

  @media only screen and (min-width: 1023px) {
    flex-direction: row;
    justify-content: center;
  }
`;
