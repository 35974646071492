import axios from "axios";

const instance = axios.create({
    baseURL: "https://linerapayrest.lineragamessub2.com.br",
    headers: {
        Authorization: "",
        'Access-Control-Allow-Origin': '*'
    }
    });

export default instance;
