import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    TextField,
} from '@mui/material';
import {Form, ModalBox} from "./styles";
import {BotaoContained} from "../../../StyledComponents";
import {useUsuariosInterno} from "../../../../contexts/usuariosinterno.context";

const ModalAdicionarUsuarioInterno = ({ open, handleClose }) => {
    const {AdicionarUsuarioInterno} = useUsuariosInterno();
    const [user, setUser] = useState({ nome: '', email: '', senha: '' });

    const handleChange = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // faz alguma coisa com o usuário
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <Form
                onSubmit={handleSubmit}
            >
                <DialogTitle>Novo Usuário</DialogTitle>
                <DialogContent>
                    <ModalBox>
                            <TextField
                                label="Nome"
                                name="nome"
                                value={user.nome}
                                onChange={handleChange}
                                required
                            />
                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                value={user.email}
                                onChange={handleChange}
                                required
                            />
                            <TextField
                                label="Senha"
                                name="senha"
                                type="password"
                                value={user.senha}
                                onChange={handleChange}
                                required
                            />

                    </ModalBox>
                </DialogContent>
                <DialogActions>
                    <BotaoContained type="submit" variant="contained" color="primary" onClick={() => AdicionarUsuarioInterno(user)}>
                        Adicionar Usuário
                    </BotaoContained>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export default ModalAdicionarUsuarioInterno;
