import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import {BotaoDanger} from "../../../StyledComponents";

const DeleteUserModal = ({ open, onClose, onDelete }) => {
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        setLoading(true);
        await onDelete();
        setLoading(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirmar exclusão</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Tem certeza que deseja excluir este usuário? Esta ação não pode ser desfeita.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <BotaoDanger onClick={handleDelete} variant="contained" color="error" disabled={loading}>
                    {loading ? 'Excluindo...' : 'Excluir'}
                </BotaoDanger>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteUserModal;
