import styled from 'styled-components';

export const ContainerPainel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  
  * {
    overflow: hidden;
  }

  @media only screen and (max-width: 1023px) {
    padding: 16px 8px;
  }
`
export const ContainerDrawerLogo = styled.div`
  display: flex;
  justify-content: center;
  height: fit-content;
  align-items: center;
`
