import { createContext, useContext, useState } from 'react'

export const LoadingContext = createContext({})

export const LoadingProvider = ({ children }) => {
    const [loadingAtivo, setLoadingAtivo] = useState(false)

    return (
        <LoadingContext.Provider value={{ loadingAtivo, setLoadingAtivo }}>
            {children}
        </LoadingContext.Provider>
    )
}

export function useLoading() {
    const context = useContext(LoadingContext);

    return context;
}
