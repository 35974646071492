import React, {useEffect, useState} from 'react'
import MUIDataTable from "mui-datatables";
import {Box, Button, Modal, TextField} from "@mui/material";
import ModalTabela from "../ModalTabela";
import {useTelegramUsuarios} from "../../../contexts/telegramUsuarios.context";
import {useTelegramGrupos} from "../../../contexts/telegramGrupos.context";
import {useProdutos} from "../../../contexts/produtos.context";
import {ContainerCopyPaste} from "../ModalTabela/style";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function TelegramUsuarios() {
    const [exibirGrupos, setExibirGrupos] = useState(false)
    const {GetUserProdutos, produtosUsuarioSelecionado, RemoverTelefoneAssociado, linkRemoverAssociado, setLinkRemoverAssociado} = useProdutos();
    const {lista, GetUsuariostelegram, setUsuarioSelecionado, totalUsuarios } =  useTelegramUsuarios();

    const options = {
        serverSide: true,
        filterType: 'checkbox',
        selectableRows: false,
        filter: false,
        viewColumns: false,
        responsive: 'simple',
        onTableChange: (action, tableState) => {
            if (action === 'changePage' || action === 'search') {
                GetUsuariostelegram(tableState.page + 1, tableState.searchText);
            }
        },
        customSearch: (searchQuery, currentRow, columns) => {
            return true;
        },
        rowsPerPage: 30,
        count: totalUsuarios,
    };

    const handleExibirGrupos = (hotmartId, userId) => {
        setUsuarioSelecionado({hotmartId, userId});
        setExibirGrupos(true)
        GetUserProdutos(hotmartId, userId);
    }

    useEffect(() => {
        GetUsuariostelegram();
    }, [])

    const columns = [{name: "Hotmart Id",
        options: {
        display: false
    }}, "Email", "Nome", "Telefone", {name: "ClienteId",
        options: {
            display: false
        }}, {
        label: "Ações",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <Button onClick={() => handleExibirGrupos(tableMeta.rowData[0], tableMeta.rowData[4]) }>
                        Ver Compras
                    </Button>
                )
            }
        }
    }];

    if(lista)
        return (
            <>
                <div style={{width: '100%'}}>
                    <MUIDataTable
                        title={"Telegram Usuarios"}
                        data={lista}
                        columns={columns}
                        options={options}
                    />
                </div>

                <ModalTabela data={produtosUsuarioSelecionado} open={exibirGrupos} onClose={() => setExibirGrupos(false)} />

                <Modal
                    onClose={() => setLinkRemoverAssociado("")}
                    open={linkRemoverAssociado != ""}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <ContainerCopyPaste>
                            <TextField variant="standard" value={linkRemoverAssociado} />
                            <Button variant="contained" onClick={() => navigator.clipboard.writeText(linkRemoverAssociado)} variant="outlined" startIcon={<ContentCopyIcon />}>
                                Copiar
                            </Button>
                        </ContainerCopyPaste>
                    </Box>
                </Modal>
            </>
        )

    return null
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
