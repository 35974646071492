import React, {useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import ModalGruposAssociados from "./ModalGruposAssociados";
import {BotaoContained} from "../../StyledComponents";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControlLabel,
    TextField,
    Select,
    MenuItem
} from "@mui/material";
import {useProdutosCliente} from "../../../contexts/produtoscliente.context";

let abrirAssociarGruposTeste;

let plataforma = {
    "1": "Hotmart",
    "5": "Monetizze"
}

const CadastroDeProdutos = () => {
    const [deletarModalOpen, setDeletarModalOpen] = useState(false);
    const [modalEditarOpen, setModalEditarOpen] = useState(false);

    const {
        produtosCliente,
        setProdutosCliente,
        produtoSelecionado,
        setProdutoSelecionado,
        GetProdutosCliente,
        AdicionarProduto,
        EditarProduto,
        DeletarProduto,
        produtoDeletarSelecionado,
        setProdutoDeletarSelecionado,
        gruposDisponiveis,
        gruposVinculados,
        idProdutoIntegracaoEditando,
        setIdProdutoIntegracaoEditando,
        ExibirGruposVinculados,
        ExibirGruposDisponiveis
    } = useProdutosCliente();

    const AssociarGrupos = async (idProdutoIntegracao) => {
        await ExibirGruposVinculados(idProdutoIntegracao);
        await ExibirGruposDisponiveis(idProdutoIntegracao);
        setIdProdutoIntegracaoEditando(idProdutoIntegracao);
    }

    const columns = [
        {
            name: "ativo",
            label: "Ativo",
            options: {
                display: false
            }
        },
        {
            name: "clienteId",
            label: "Cliente Id",
            options: {
                display: false
            }
        },
        {
            name: "idProdutoIntegracao",
            label: "Id Produto integração",
            options: {
                display: false
            }
        },
        {
            name: "integracaoId",
            label: "Integração Id",
            options: {
                display: false
            }
        },
        {
            name: "integracaoIdInternoTxt",
            label: "Integracao Id Interno",
            options: {
                display: false
            }
        },
        {
            name: "integracaoIdInternoValor",
            label: "Integracao Id Interno valor",
            options: {
                display: false
            }
        },
        {
            name: "nomeProduto",
            label: "Nome Produto",
        },
        {
            name: "Ações",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { width: '200px' } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <Button onClick={() => AssociarGrupos(tableMeta.rowData[2])}>Associar Grupos</Button>
                            <Button onClick={() => {
                                setProdutoSelecionado({
                                    ativo: tableMeta.rowData[0],
                                    clienteId: tableMeta.rowData[1],
                                    idProdutoIntegracao: tableMeta.rowData[2],
                                    integracaoId: plataforma[tableMeta.rowData[3]],
                                    integracaoIdInternoTxt: tableMeta.rowData[4],
                                    integracaoIdInternoValor: tableMeta.rowData[5],
                                    nomeProduto: tableMeta.rowData[6]
                                })}
                            }>Editar</Button>
                            <Button onClick={() => setProdutoDeletarSelecionado({
                                NomeProduto: tableMeta.rowData[6],
                                IdProdutoHotmart: tableMeta.rowData[5],
                            })}>Excluir</Button>
                        </div>
                    );
                },
            },
        },
    ];

    const options = {
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        pagination: false,
        responsive: 'simple'
    };
    const [abrirAsociarGrupos, setAbrirAssociarGrupos] = useState(false)
    abrirAssociarGruposTeste = setAbrirAssociarGrupos;

    let userInteract;

    useEffect(() => {
        GetProdutosCliente()
    }, [])

    useEffect(() => {
        console.log(produtoSelecionado)
    }, [produtoSelecionado])

    if(produtosCliente)
        return (
            <>
                <div style={{width: '100%'}}>
                    <BotaoContained variant={"contained"} style={{ margin: '16px' }} onClick={() => setModalEditarOpen(true)}>
                        Adicionar Produto
                    </BotaoContained>
                    <MUIDataTable
                        title={"Lista de Produtos"}
                        data={produtosCliente}
                        columns={columns}
                        options={options}
                    />
                    <ModalGruposAssociados open={idProdutoIntegracaoEditando != null} handleClose={() => setIdProdutoIntegracaoEditando(null)} />
                    <ModalAdicionar open={modalEditarOpen} handleClose={() => setModalEditarOpen(false)} handleSave={AdicionarProduto} clean={setModalEditarOpen} />

                    {   produtoSelecionado &&
                        <Dialog open={produtoSelecionado != null} onClose={() => setProdutoSelecionado(null)}>
                            <DialogTitle>Editar Produto</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Nome Produto"
                                    type="text"
                                    fullWidth
                                    value={produtoSelecionado.nomeProduto}
                                    onChange={e => setProdutoSelecionado({...produtoSelecionado, nomeProduto: e.target.value})}
                                />
                                <label>Plataforma de Integração:</label>
                                <Select
                                    value={produtoSelecionado.integracaoId}
                                    onChange={e => setProdutoSelecionado({...produtoSelecionado, integracaoId: e.target.value})}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Plataforma de Integração' }}
                                    fullWidth
                                >
                                    <MenuItem value="" disabled>
                                        Plataforma de Integração
                                    </MenuItem>
                                    <MenuItem value={'Hotmart'}>Hotmart</MenuItem>
                                    <MenuItem value={'Monetizze'}>Monetizze</MenuItem>
                                </Select>
                                <label>Ativo: </label>
                                <FormControlLabel
                                    control={<Checkbox checked={produtoSelecionado.ativo} onChange={() => setProdutoSelecionado({...produtoSelecionado, ativo: !produtoSelecionado.ativo})} />}
                                    label="Ativo"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setProdutoSelecionado(null)}>Cancelar</Button>
                                <Button onClick={() => EditarProduto()} color="primary">
                                    Salvar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }

                    {   produtoDeletarSelecionado &&
                        <Dialog open={produtoDeletarSelecionado != null} onClose={() => setProdutoDeletarSelecionado(null)}>
                            <DialogTitle>Confirm Delete</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Você tem certeza que quer deletar este grupo?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setProdutoDeletarSelecionado("")}>Cancelar</Button>
                                <Button onClick={() => DeletarProduto()} variant="contained" color="error" autoFocus>
                                    Deletar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                </div>
            </>
        );

    return null
};

const ModalAdicionar = ({ open, handleClose, handleSave, clean }) => {
    const [nomeProduto, setNomeProduto] = useState('');
    const [IdProdutoHotmart, setIdProdutoHotmart] = useState('');
    const [platform, setPlatform] = useState('');

    const handlePlatformChange = (event) => {
        setPlatform(event.target.value);
    };

    const ClearData = () => {
        setNomeProduto("");
        setIdProdutoHotmart("");
        clean(false);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Adicionar Produto</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Nome Produto"
                    type="text"
                    fullWidth
                    value={nomeProduto}
                    onChange={e => setNomeProduto(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Codigo Produto Externo"
                    type="text"
                    fullWidth
                    value={IdProdutoHotmart}
                    onChange={e => setIdProdutoHotmart(e.target.value)}
                />
                <Select
                    value={platform}
                    onChange={handlePlatformChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Plataforma de Integração' }}
                    fullWidth
                >
                    <MenuItem value="" disabled>
                        Plataforma de Integração
                    </MenuItem>
                    <MenuItem value={'Hotmart'}>Hotmart</MenuItem>
                    <MenuItem value={'Monetizze'}>Monetizze</MenuItem>
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={() => handleSave(nomeProduto, IdProdutoHotmart, platform, ClearData)} color="primary">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CadastroDeProdutos;
