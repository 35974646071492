import instance from './instance';

class UserService {
    CreateUser(user) {
        return instance.post('api/user', user);
    }
    ValidateEmail(email) {
        return instance.post('api/user/validatemail', {
            email: email,
        });
    }
    ValidateCpf(cpf) {
        return instance.post('/api/user/validatecpf', {
            cpf: cpf,
        });
    }
    ValidateUsername(username) {
        return instance.post('/api/user/validateuser', {
            username: username,
        });
    }
}

export default new UserService();
