import logo from './logo.svg';
import './App.css';
import Site from "./components/Site";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "./Theme";

function App() {
  return (
    <>
        <ThemeProvider theme={theme}>
            <Site />
        </ThemeProvider>
    </>
  );
}

export default App;
