import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    ReactElement,
} from 'react';

import instance from '../services/instance';

const AuthContext = createContext({});

export const AuthProvider = ({
                                                             children,
                                                         }) => {
    const [user, setUser] = useState("");
    const [dadosUser, setDadosUser] = useState({
        name: '',
        lastname: '',
        email: '',
        cpf: 0
    })
    const [role, setRole] = useState()
    const [idUsuario, setIdUsuario] = useState()

    useEffect(() => {
        const JWT = localStorage.getItem('@App:token');
        const ClientId = localStorage.getItem('@App:ClientId');

        console.log('Token: ', JWT, 'User: ', ClientId)

        if (JWT) {
            instance.defaults.headers.Authorization = `Bearer ${JWT}`;
            setUser({
                JWT,
                ClientId
            })
        }
    }, []);

    useEffect(()=>{
        GetPermissao(localStorage.getItem('@IdUsuario')).then((response)=>{
            console.log(response)
            localStorage.setItem('@RoleId', response)
            setRole(response)
        })
    }, [idUsuario])

    async function GetPermissao(IdUsuario) {
        const response = await instance.get(`/api/LineraPay/getPermissaoUsuario?UsuarioId=${IdUsuario}`)
        console.log(response.data[0].roleId)
        return response.data[0].roleId
    }

    async function Login(userData) {
        await instance.post('/api/Token/login', userData).then(response => {
            console.log("Dados User", response)

            instance.defaults.headers.Authorization = `Bearer ${response.data.ClienteId}`;
            setUser({JWT: response.data.JWT, ClientId: response.data.ClienteId})

            localStorage.setItem('@App:token', response.data.JWT);
            localStorage.setItem('@App:ClientId', response.data.ClienteId);
            localStorage.setItem('@IdUsuario', response.data.IdUsuario)
            setIdUsuario(response.data.IdUsuario)
            
        });
        
    }

    function Logout() {
        localStorage.setItem('@App:token', '')
        localStorage.setItem('@App:ClientId', '');
        setUser(null);
    }

    return (
        <AuthContext.Provider value={{ signed: Boolean(user),name: dadosUser.name, lastName: dadosUser.lastname, email: dadosUser.email, cpf: dadosUser.cpf, user, Login, Logout, LogoutFunction: Logout, role, setRole }}>
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const context = useContext(AuthContext);

    return context;
}
