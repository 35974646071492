import React, {useEffect, useState} from "react";
import {Avatar, Button, Modal} from "@mui/material";
import Box from "@mui/material/Box";
import {
    BotaoMobile,
    BotoesDesktop,
    ContainerSelect,
    ContainerSelects,
    Legenda,
    SelectBotaoContainer,
    SelectMultiplo
} from "./styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {BotaoContained, BotaoContainedSecundario, H1Modal} from "../../../StyledComponents";
import {useProdutosCliente} from "../../../../contexts/produtoscliente.context";

const ModalGruposAssociados = ({ open, handleClose }) => {
    const [leftList, setLeftList] = useState([]);
    const [rightList, setRightList] = useState([]);

    const {gruposDisponiveis, gruposVinculados, AssociarGrupos: AssociarGruposFunc } = useProdutosCliente();

    useEffect(() => {
        console.log(gruposVinculados)

        if(gruposDisponiveis)
        setLeftList(gruposDisponiveis.map(g => g.nomeGrupoTelegram))
        if(gruposVinculados)
        setRightList(gruposVinculados.map(g => g.nomeGrupoTelegram))
    }, [open])

    const handleAddItem = () => {
        const selectedItems = document.getElementById("select-multiple-left").selectedOptions;
        const newItems = [...rightList];

        for (let i = 0; i < selectedItems.length; i++) {
            const item = selectedItems[i].value;
            if (!newItems.includes(item)) {
                newItems.push(item);
            }
        }

        setRightList(newItems);
        setLeftList(leftList.filter((item) => !newItems.includes(item)));
    };

    const handleRemoveItem = () => {
        const selectedItems = document.getElementById("select-multiple-right").selectedOptions;
        const newItems = [...leftList];

        for (let i = 0; i < selectedItems.length; i++) {
            const item = selectedItems[i].value;
            if (!newItems.includes(item)) {
                newItems.push(item);
            }
        }

        setLeftList(newItems);
        setRightList(rightList.filter((item) => !newItems.includes(item)));
    };

    const AssociarGrupos = () => {
        let gruposAAssociar = [];

        gruposDisponiveis.forEach(g => {
            rightList.forEach(gl => {
                if(gl === g.nomeGrupoTelegram)
                    gruposAAssociar.push(g)
            })
        })
        gruposVinculados.forEach(g => {
            rightList.forEach(gl => {
                if(gl === g.nomeGrupoTelegram)
                    gruposAAssociar.push(g)
            })
        })

        AssociarGruposFunc(gruposAAssociar.map(g => g.idGrupoTelegram));
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <H1Modal>Associar Grupos</H1Modal>
                <ContainerSelects>
                    <ContainerSelect>
                        <Legenda>Grupos disponíveis</Legenda>
                        <SelectBotaoContainer>
                            <SelectMultiplo multiple style={{width: '100%', height: '100%'}} id="select-multiple-left">
                                {leftList.map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </SelectMultiplo>
                            <BotaoMobile>
                                <BotaoContained variant="contained" color="primary" onClick={handleAddItem}>
                                    <ArrowDropDownIcon />
                                </BotaoContained>
                            </BotaoMobile>
                        </SelectBotaoContainer>
                    </ContainerSelect>
                    <BotoesDesktop>
                        <BotaoContained variant="contained" color="primary" onClick={handleAddItem}>
                            &gt;
                        </BotaoContained>
                        <BotaoContained variant="contained" color="primary" onClick={handleRemoveItem}>
                            &lt;
                        </BotaoContained>
                    </BotoesDesktop>
                    <ContainerSelect>
                        <Legenda>
                            Grupos Associados
                        </Legenda>
                        <SelectBotaoContainer>
                            <SelectMultiplo multiple
                                 style={{width: '100%'}}
                                 id="select-multiple-right">
                                {rightList.map((item, index) => (
                                    <>
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    </>
                                ))}
                            </SelectMultiplo>
                            <BotaoMobile>
                                <BotaoContained variant="contained" color="primary" onClick={handleRemoveItem}>
                                    <ArrowDropUpIcon />
                                </BotaoContained>
                            </BotaoMobile>
                        </SelectBotaoContainer>
                    </ContainerSelect>
                </ContainerSelects>
                <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                    <BotaoContainedSecundario onClick={handleClose}>
                        Fechar
                    </BotaoContainedSecundario>
                    <BotaoContained color="primary" onClick={() => AssociarGrupos()}>
                        Associar
                    </BotaoContained>
                </div>
            </Box>
        </Modal>
    );
};

export default ModalGruposAssociados;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'hidden'
};
