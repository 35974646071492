import { useEffect, useState } from 'react';
import { FormControl, TextField, Button, Box } from '@mui/material';
import {Container, ModalContainer, ModalContent} from "./styles";
import HotmartService from '../../../services/hotmart.service';
import { useLoading } from "../../../contexts/loading.context";

export default function HotmartForm() {
  const [hotmartCode, setHotmartCode] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [error, setError] = useState(null);
  const { setLoadingAtivo } = useLoading();

  const handleHotmartCodeChange = (event) => {
    setHotmartCode(event.target.value);
  };

  const handleCodeSubmit = async (event) => {
    event.preventDefault();
    setLoadingAtivo(true); // Ativa o loading
    try {
      await HotmartService.ProcessarHotmart(hotmartCode);
      setOpenModal(true); // Abre o modal após a requisição
    } catch (error) {
      console.error(error);
      setError(error.response.data + ".");
      console.log(error)
      setErrorModal(true);
    } finally {
      setLoadingAtivo(false); // Desativa o loading independente do resultado
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setHotmartCode('');
  };

  const handleCloseErrorModal = () => {
    setErrorModal(false);
    setHotmartCode('');
  };

  return (
    <Container>
      <form onSubmit={handleCodeSubmit}>
        <FormControl sx={{ '& > :not(style)': { mb: 2 } }}>
          <TextField
            label="Hotmart Code"
            name="hotmartCode"
            variant="outlined"
            value={hotmartCode}
            onChange={handleHotmartCodeChange}
          />
          <Button type="submit">Atualizar Venda</Button>
        </FormControl>
      </form>
      <ModalContainer
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <p>Venda Hotmart Atualizada.</p>
          <Button onClick={handleCloseModal}>Ok</Button>
        </ModalContent>
      </ModalContainer>
      <ModalContainer
        open={errorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="modal-error-modal-title"
        aria-describedby="modal-error-modal-description"
      >
        <ModalContent>
          <p style={{
            color: 'red',
            fontWeight: 'bold',
          }}>{error ?? "Ocorreu um erro."}</p>
          <Button onClick={handleCloseErrorModal}>Ok</Button>
        </ModalContent>
      </ModalContainer>
    </Container>
  );
}
