import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    ReactElement,
} from 'react';

import instance from '../services/instance';
import {useAuth} from "./autenticacao.context";
import {useLoading} from "./loading.context";
import axios from "axios";
import {useProdutosCliente} from "./produtoscliente.context";
import { v4 as uuidv4 } from 'uuid';

const WhitelistContext = createContext({});

export const WhitelistProvider = ({ children }) => {
    const {user} = useAuth();
    const {setLoadingAtivo} = useLoading();
    const {produtosCliente} = useProdutosCliente();

    const [clientesWhitelist, setClientesWhitelist] = useState(null);
    const [clienteWhitelistSelecionado, setClienteWhitelistSelecionado] = useState(null)
    const [clienteWhitelistDeletarSelecionado, setClienteWhitelistDeletarSelecionado] = useState(null)

    const GetWhitelistClientes = async () => {
        setLoadingAtivo(true)
        await instance.get("/api/LineraPay/getWhitelistClientes", { params: { ClientId: user.ClientId } })
            .then(r => {
                console.log("Whitelist Clientes", r.data)
                setClientesWhitelist(r.data)
                setLoadingAtivo(false)
            })
            .catch(e => {
                console.error(e)
                setLoadingAtivo(false)
            })
    }

    const AdicionarWhitelistCliente = async (DadosWhitelist) => {
        setLoadingAtivo(true)
        await instance.post("/api/LineraPay/addClienteWhitelist", {
            IdProduto: DadosWhitelist.IdProduto,
            Nome: DadosWhitelist.Nome,
            Email: DadosWhitelist.Email,
            Telefone: DadosWhitelist.Telefone,
            ClientId: user.ClientId,
        })
            .then(async r => {
                const produto = produtosCliente.find(p => p.integracaoIdInternoValor === DadosWhitelist.IdProduto)
                await instance.post('/CompraAprovada', {
                    "id": uuidv4(),
                    "creation_date": 1680907409743,
                    "event": "PURCHASE_APPROVED",
                    "version": "2.0.0",
                    "data": {
                        "product": {
                            "id": DadosWhitelist.IdProduto,
                            "ucode": "b57a02d1-243e-4ea1-a2ea-7d4765f1d2a5",
                            "name": produto.nomeProduto,
                            "has_co_production": false
                        },
                        "affiliates": [
                            {
                                "affiliate_code": "",
                                "name": ""
                            }
                        ],
                        "buyer": {
                            "email": DadosWhitelist.Email,
                            "name": DadosWhitelist.Nome,
                            "checkout_phone": DadosWhitelist.Telefone
                        },
                        "producer": {
                            "name": "ORTEGATIPS MRP LTDA"
                        },
                        "commissions": [
                            {
                                "value": 31.99,
                                "source": "MARKETPLACE"
                            },
                            {
                                "value": 511.71,
                                "source": "PRODUCER"
                            }
                        ],
                        "purchase": {
                            "approved_date": 1680907405000,
                            "full_price": {
                                "value": 600
                            },
                            "original_offer_price": {
                                "value": 543.7,
                                "currency_value": "BRL"
                            },
                            "price": {
                                "value": 543.7
                            },
                            "offer": {
                                "code": "hru4hzg9"
                            },
                            "checkout_country": {
                                "name": "Brasil",
                                "iso": "BR"
                            },
                            "order_bump": {
                                "is_order_bump": false
                            },
                            "order_date": 1680907404000,
                            "status": "APPROVED",
                            "transaction": "HP11716809074030",
                            "buyer_ip": "131.72.244.208",
                            "payment": {
                                "installments_number": 6,
                                "type": "CREDIT_CARD"
                            }
                        },
                        "subscription": {
                            "status": "ACTIVE",
                            "plan": {
                                "id": 522565,
                                "name": "Semestral"
                            },
                            "subscriber": {
                                "code": `WLIST${r.data.id}`
                            }
                        }
                    },
                    "hottok": "4Ql2iewPyeuIeR5bUocbyWKPWJ4P6b35c38fdf-2c8e-4ef1-8a5f-df2ce7b44266"
                })
            })
            .catch(e => {
                console.error(e)
                setLoadingAtivo(false)
            })
        await GetWhitelistClientes();
        setLoadingAtivo(false)
    }

    const EditarWhitelist = async () => {
        setLoadingAtivo(true)
        await instance.put("/api/LineraPay/editarGrupoTelegram", {
            ClienteId: user.ClientId,
            IdGrupoTelegram: clienteWhitelistSelecionado.idGrupoTelegram,
            TelegramId: clienteWhitelistSelecionado.telegramId,
            NomeGrupoTelegram: clienteWhitelistSelecionado.nomeGrupoTelegram,
            ProdutoIntegracaoId: clienteWhitelistSelecionado.produtoIntegracaoId
        })
        setClienteWhitelistSelecionado(null)
        await GetWhitelistClientes();
        setLoadingAtivo(false)
    }

    const DeletarWhitelistCliente = async () => {
        setLoadingAtivo(true)
        await instance.post("/api/LineraPay/deleteWhitelistCliente", {
                Id: clienteWhitelistDeletarSelecionado.Id,
                ProdutoHotmartIdInterno: clienteWhitelistDeletarSelecionado.ProdutoHotmartIdInterno
            })
        setClienteWhitelistDeletarSelecionado(null)
        await GetWhitelistClientes();
        setLoadingAtivo(false)
    }

    return (
        <WhitelistContext.Provider value={
            {
                clientesWhitelist,
                setClientesWhitelist,
                clienteWhitelistSelecionado,
                setClienteWhitelistSelecionado,
                GetWhitelistClientes,
                AdicionarWhitelistCliente,
                EditarWhitelist,
                DeletarWhitelistCliente,
                clienteWhitelistDeletarSelecionado,
                setClienteWhitelistDeletarSelecionado
            }}>
            {children}
        </WhitelistContext.Provider>
    );
};

export function useWhitelist() {
    const context = useContext(WhitelistContext);

    return context;
}
