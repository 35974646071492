import styled from 'styled-components'


export const ContainerSelects = styled.div`
  display: flex;
  gap: 18px;
  padding: 0 0 32px 0;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`

export const SelectMultiplo = styled.select`
  border: 1px solid #B0B5C1;
  border-radius: 4px;
  height: 221px !important;

  option {
    font-family: 'Bototo', sans-serif;
    font-size: 16px;
    border-radius: 8px;
    padding: 10px;
    margin: 5px;
    background-color: white;
  }

  option:hover {
    background-color: #f2f2f2;
  }

  option:selected {
    background: #E5E9F2;
    color: white;
  }
`

export const Legenda = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #231F20;
`

export const ContainerSelect = styled.div`
  width: 100%;
  height: 100%;
`

export const BotoesDesktop = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-top: 42px;
  height: fit-content;

  @media only screen and (max-width: 900px) {
    display: none;
  }
`

export const SelectBotaoContainer = styled.div`
  display: flex;
  
  @media only screen and (max-width: 900px) {
    display: flex;
    gap: 8px;
    flex-direction: row;
  }
`

export const BotaoMobile = styled.div`
    @media only screen and (min-width: 900px) {
     display: none;
    }
`
