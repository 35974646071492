import {createContext, useContext, useEffect, useState} from 'react'
import instance from '../services/instance';

export const ClientesContext = createContext({})

export const ClientesProvider = ({ children }) => {
    const [clientes, setClientes] = useState("")

    const GetClientes = async () => {
        let listaClientes = await instance.post("/api/LineraPay/clientes");
        setClientes(listaClientes.data);
    }

    return (
        <ClientesContext.Provider value={{ clientes, setClientes, GetClientes }}>
            {children}
        </ClientesContext.Provider>
    )
}

export function useClientes() {
    const context = useContext(ClientesContext);

    return context;
}
