import styled from 'styled-components'
import { Modal } from '@mui/material';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    padding: 20px;
    gap: 16px;
    width: 100%;

    .MuiFormControl-root {
        max-width: 400px;
        width: 100%;
    }
    form {
        max-width: 400px;
        width: 100%;
    }

    button {
        width: 100%;
        max-width: 400px;
        background-color: #143182;
        color: white;
    }
    button:hover {
        background-color: #0d1d4d;
    }
`

export const ModalContainer = styled(Modal)`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
`

export const ModalContent = styled.div`
    background: #FFF;
    padding: 16px;
    border-radius: 8px;
    p{
        margin-bottom: 16px;
        font-size: 24px;
    }
    button{
        max-width: 400px;
        background-color: #143182;
        color: white;
        width: 100vw;
        max-width: 400px;
    }
    button:hover {
        background-color: #0d1d4d;
    }
`
