import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import { Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    TextField,
    FormControlLabel,
    DialogContentText
} from '@mui/material';
import {BotaoContained} from "../../StyledComponents";
import {useGruposCliente} from "../../../contexts/gruposcliente.context";

const CadastroGrupos = () => {
    const { GetGruposCliente, gruposCliente, AdicionarGrupo, setGrupoSelecionado, grupoSelecionado, EditarGrupo, setGrupoDeletadoSelecionado, grupoDeletarSelecionado, DeletarGrupo } = useGruposCliente()
    const [ modalEditarOpen, setModalEditarOpen ] = useState(false);
    const [ deletarModalOpen, setDeletarModalOpen ] = useState(true);

    const AdicionarUmGrupo = async (TelegramId, NomeGrupoTelegram, ClearData) => {
        await AdicionarGrupo(TelegramId, NomeGrupoTelegram)
        ClearData();
        setModalEditarOpen(false);
    }

    const columns = [
        {
            name: "idGrupoTelegram",
            label: "Id Grupo Telegram",
            options: {
                display: false
            }
        },
        {
            name: "clienteId",
            label: "Cliente ID",
            options: {
                display: false
            }
        },
        {
            name: "telegramId",
            label: "Telegram Id",
        },
        {
            name: "ativo",
            label: "Ativo",
            options: {
                display: false
            }
        },
        {
            name: "produtoIntegracaoId",
            label: "Produto Integracao Id",
            options: {
                display: false
            }
        },
        {
            name: "nomeGrupoTelegram",
            label: "Nome do Grupo",
        },
        {
            name: "actions",
            label: "Ações",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellProps: () => ({ style: { width: '200px' } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Button color="primary" size="small" onClick={() => {
                                setGrupoSelecionado({
                                    idGrupoTelegram: tableMeta.rowData[0],
                                    clienteId: tableMeta.rowData[1],
                                    telegramId: tableMeta.rowData[2],
                                    ativo: tableMeta.rowData[3],
                                    produtoIntegracaoId: tableMeta.rowData[4],
                                    nomeGrupoTelegram: tableMeta.rowData[5]
                                })
                            }}>
                                Editar
                            </Button>
                            <Button color="primary" size="small" onClick={() => {
                                setGrupoDeletadoSelecionado({
                                    ChatIdGrupoTelegram: tableMeta.rowData[2],
                                    IdProdutoHotmart: tableMeta.rowData[4],
                                })
                            }}>
                                Deletar
                            </Button>
                        </>
                    );
                }
            }
        }
    ];



    const options = {
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        pagination: false,
        responsive: 'simple'
    };

    useEffect(() => {
        GetGruposCliente()
    }, [])

    if(gruposCliente)
        return (
            <>
                <div style={{width: '100%'}}>
                    <BotaoContained variant={"contained"} style={{ margin: '16px' }} onClick={() => setModalEditarOpen(true) }>
                        Adicionar Grupo
                    </BotaoContained>
                    <MUIDataTable
                        title={"Grupos"}
                        data={gruposCliente}
                        columns={columns}
                        options={options}
                    />

                    <ModalAdicionar open={modalEditarOpen} handleClose={() => setModalEditarOpen(false)} handleSave={AdicionarUmGrupo} />
                </div>

                {   grupoSelecionado &&
                    <Dialog open={grupoSelecionado != null} onClose={() => setGrupoSelecionado(null)}>
                        <DialogTitle>Editar Grupo</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Telegram Id"
                                type="text"
                                fullWidth
                                value={grupoSelecionado.telegramId}
                                onChange={e => setGrupoSelecionado({...grupoSelecionado, telegramId: e.target.value})}
                            />
                            <TextField
                                margin="dense"
                                label="Nome do Grupo"
                                type="text"
                                fullWidth
                                value={grupoSelecionado.nomeGrupoTelegram}
                                onChange={e => setGrupoSelecionado({...grupoSelecionado, nomeGrupoTelegram: e.target.value})}
                            />
                            <label>Ativo: </label>
                            <FormControlLabel
                                control={<Checkbox checked={grupoSelecionado.ativo} onChange={() => setGrupoSelecionado({...grupoSelecionado, ativo: !grupoSelecionado.ativo})} />}
                                label="Ativo"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setGrupoSelecionado(null)}>Cancelar</Button>
                            <Button onClick={() => EditarGrupo()} color="primary">
                                Salvar
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

                {   grupoDeletarSelecionado &&
                    <Dialog open={deletarModalOpen} onClose={() => setGrupoDeletadoSelecionado(null)}>
                        <DialogTitle>Confirm Delete</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Você tem certeza que quer deletar este grupo?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setGrupoDeletadoSelecionado("")}>Cancelar</Button>
                            <Button onClick={() => DeletarGrupo()} variant="contained" color="error" autoFocus>
                                Deletar
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </>
        );

    return null
}

const ModalAdicionar = ({ open, handleClose, handleSave }) => {
    const [telegramId, setTelegramId] = useState('');
    const [groupName, setGroupName] = useState('');

    const ClearData = () => {
        setTelegramId("");
        setGroupName("");
        handleClose();
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Adicionar Grupo</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Codigo do Grupo do Telegram"
                    type="text"
                    fullWidth
                    value={telegramId}
                    onChange={e => setTelegramId(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Nome do Grupo"
                    type="text"
                    fullWidth
                    value={groupName}
                    onChange={e => setGroupName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={() => handleSave(telegramId, groupName, ClearData)} color="primary">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CadastroGrupos;
