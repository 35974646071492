import instance from './instance';

class ConfiguraService {
    GetProcessorId() {
        return instance.get('api/conf');
    }
    GetDocs() {
        return instance.get('/api/conf/docs');
    }
}

export default new ConfiguraService();
