import React from 'react'
import {Route, Routes} from 'react-router-dom';
import styles from './mobile.module.css'
import PainelDeControle from './PainelDeControle/index'
import {useAuth} from "../contexts/autenticacao.context";
import LoginPage from "../Pages/Auth/Login";
import ResetPassword from "../Pages/Auth/ResetPassword";
import NewPassword from "../Pages/Auth/NewPassword";
import RegisterPage from "../Pages/Auth/Register";
import {ClientesProvider} from "../contexts/cliente.context";
import {TelegramUsuariosProvider} from "../contexts/telegramUsuarios.context";
import {TelegramGruposProvider} from "../contexts/telegramGrupos.context";
import {ProdutosProvider} from "../contexts/produtos.context";
import Loading from "./Loading";
import {LoadingProvider} from "../contexts/loading.context";
import {GruposClienteProvider} from "../contexts/gruposcliente.context";
import {ProdutosClienteProvider} from "../contexts/produtoscliente.context";
import {UsuariosInternoProvider} from "../contexts/usuariosinterno.context";
import {WhitelistProvider} from "../contexts/whitelist.context";
/*import Home from './Site/Home';*/

export default function Site() {
    const { signed } = useAuth();

    return (
        <>
            <LoadingProvider>
                <Loading />
                {!signed ? (
                    <Routes>
                        <Route path='/' element={<LoginPage />} />
                        <Route path='/ResetPassword' element={<ResetPassword />} />
                        <Route path='/NewPassword' element={<NewPassword />} />
                        <Route path='/Register' element={<RegisterPage />} />
                    </Routes>
                ) : (
                        <ClientesProvider>
                            <TelegramUsuariosProvider>
                                <TelegramGruposProvider>
                                    <ProdutosProvider>
                                        <GruposClienteProvider>
                                            <ProdutosClienteProvider>
                                                <UsuariosInternoProvider>
                                                    <WhitelistProvider>
                                                        <Routes>
                                                            <Route exact path='/' element={<PainelDeControle />} />
                                                        </Routes>
                                                    </WhitelistProvider>
                                                </UsuariosInternoProvider>
                                            </ProdutosClienteProvider>
                                        </GruposClienteProvider>
                                    </ProdutosProvider>
                                </TelegramGruposProvider>
                            </TelegramUsuariosProvider>
                        </ClientesProvider>

                )}
            </LoadingProvider>
        </>
    )
}

/*<Route exact path='/' component={Home} />*/
