import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    TextField,
    FormControlLabel,
    DialogContentText, Select, MenuItem, Box, Modal
} from '@mui/material';
import {BotaoContained} from "../../StyledComponents";
import {useWhitelist} from "../../../contexts/whitelist.context";
import {useProdutosCliente} from "../../../contexts/produtoscliente.context";
import {useProdutos} from "../../../contexts/produtos.context";
import {ContainerCopyPaste} from "../ModalTabela/style";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Whitelist = () => {
    const { GetWhitelistClientes, clientesWhitelist, AdicionarWhitelistCliente, setclienteWhitelistSelecionado, clienteWhitelistSelecionado, EditarGrupo, setClienteWhitelistDeletarSelecionado, clienteWhitelistDeletarSelecionado, DeletarWhitelistCliente } = useWhitelist()
    const [ modalEditarOpen, setModalEditarOpen ] = useState(false);
    const [ deletarModalOpen, setDeletarModalOpen ] = useState(true);
    const {
        GetProdutosCliente,
        produtosCliente
    } = useProdutosCliente();

    const {RemoverTelefoneAssociado, setLinkRemoverAssociado, linkRemoverAssociado, GerarLinkBotAssociacao, linkBotAssociacao, setLinkBotAssociacao} = useProdutos();

    const AdicionarAWhitelist = async (DadosWhitelist, ClearData) => {
        await AdicionarWhitelistCliente(DadosWhitelist)
        ClearData();
        setModalEditarOpen(false);
    }

    const columns = [
        {
            name: "Id",
            label: "Id",
            options: {
                display: false
            }
        },
        {
            name: "Id Produto",
            label: "Id Produto",
            options: {
                display: false
            }
        },
        {
            name: "Nome",
            label: "Nome",
        },
        {
            name: "Email",
            label: "Email",
        },
        {
            name: "Telefone",
            label: "Telefone",
        },
        {
            name: "Client Id",
            label: "Client Id",
            options: {
                display: false
            }
        },
        {
            name: "urlTelegram",
            label: "urlTelegram",
            options: {
                display: false
            }
        },
        {
            name: "codigo",
            label: "Codigo",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <p>wlist{tableMeta.rowData[0]}</p>
                        </>
                    );
                }
            }
        },
        {
            name: "actions",
            label: "Ações",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellProps: () => ({ style: { width: '300px' } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Button style={{ textAlign: "left" }} onClick={() => GerarLinkBotAssociacao(tableMeta.rowData[6])}>
                                Gerar Link Bot Associação
                            </Button>
                            <Button color="primary" size="small" onClick={() => {
                                setClienteWhitelistDeletarSelecionado({
                                    Id: tableMeta.rowData[0],
                                    ProdutoHotmartIdInterno: `WLIST${tableMeta.rowData[0]}`
                                })
                            }}>
                                Deletar
                            </Button>
                            {/*<Button style={{ textAlign: "left" }} onClick={() => RemoverTelefoneAssociado(`WLIST${tableMeta.rowData[0]}`)}>*/}
                            {/*    Remover Telefone Associado*/}
                            {/*</Button>*/}
                        </>
                    );
                }
            }
        }
    ];



    const options = {
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        pagination: false,
        responsive: 'simple'
    };

    useEffect(() => {
        GetProdutosCliente();
        GetWhitelistClientes();
    }, [])

    if(clientesWhitelist)
        return (
            <>
                <div style={{width: '100%'}}>
                    <BotaoContained variant={"contained"} style={{ margin: '16px' }} onClick={() => setModalEditarOpen(true) }>
                        Adicionar a Whitelist
                    </BotaoContained>
                    <MUIDataTable
                        title={"Grupos"}
                        data={clientesWhitelist}
                        columns={columns}
                        options={options}
                    />

                    <ModalAdicionar open={modalEditarOpen} handleClose={() => setModalEditarOpen(false)} handleSave={AdicionarAWhitelist} />
                </div>

                {   clienteWhitelistSelecionado &&
                    <Dialog open={clienteWhitelistSelecionado != null} onClose={() => setclienteWhitelistSelecionado(null)}>
                        <DialogTitle>Editar Grupo</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Telegram Id"
                                type="text"
                                fullWidth
                                value={clienteWhitelistSelecionado.telegramId}
                                onChange={e => setclienteWhitelistSelecionado({...clienteWhitelistSelecionado, telegramId: e.target.value})}
                            />
                            <TextField
                                margin="dense"
                                label="Nome do Grupo"
                                type="text"
                                fullWidth
                                value={clienteWhitelistSelecionado.nomeGrupoTelegram}
                                onChange={e => setclienteWhitelistSelecionado({...clienteWhitelistSelecionado, nomeGrupoTelegram: e.target.value})}
                            />
                            <label>Ativo: </label>
                            <FormControlLabel
                                control={<Checkbox checked={clienteWhitelistSelecionado.ativo} onChange={() => setclienteWhitelistSelecionado({...clienteWhitelistSelecionado, ativo: !clienteWhitelistSelecionado.ativo})} />}
                                label="Ativo"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setclienteWhitelistSelecionado(null)}>Cancelar</Button>
                            <Button onClick={() => EditarGrupo()} color="primary">
                                Salvar
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

                {   clienteWhitelistDeletarSelecionado &&
                    <Dialog open={deletarModalOpen} onClose={() => setClienteWhitelistDeletarSelecionado(null)}>
                        <DialogTitle>Confirm Delete</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Você tem certeza que quer deletar este grupo?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setClienteWhitelistDeletarSelecionado("")}>Cancelar</Button>
                            <Button onClick={() => DeletarWhitelistCliente()} variant="contained" color="error" autoFocus>
                                Deletar
                            </Button>
                        </DialogActions>
                    </Dialog>
                }

                <Modal
                    onClose={() => setLinkRemoverAssociado("")}
                    open={linkRemoverAssociado != ""}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <ContainerCopyPaste>
                            <TextField variant="standard" value={linkRemoverAssociado} />
                            <Button variant="contained" onClick={() => navigator.clipboard.writeText(linkRemoverAssociado)} variant="outlined" startIcon={<ContentCopyIcon />}>
                                Copiar
                            </Button>
                        </ContainerCopyPaste>
                    </Box>
                </Modal>

                {linkBotAssociacao &&
                    <Modal
                        onClose={() => setLinkBotAssociacao("")}
                        open={linkBotAssociacao != ""}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <ContainerCopyPaste>
                                <TextField variant="standard" value={linkBotAssociacao} />
                                <Button variant="contained" onClick={() => navigator.clipboard.writeText(linkBotAssociacao)} variant="outlined" startIcon={<ContentCopyIcon />}>
                                    Copiar
                                </Button>
                            </ContainerCopyPaste>
                        </Box>
                    </Modal>
                }
            </>
        );

    return null
}

const ModalAdicionar = ({ open, handleClose, handleSave }) => {
    const [whitelistDados, setWhiteListDados] = useState({
        Nome: '',
        Email: '',
        IdProduto: '0',
        Telefone: '',
    })

    const {
        produtosCliente
    } = useProdutosCliente();

    const ClearData = () => {
        setWhiteListDados({
            Nome: '',
            Email: '',
            IdProduto: '0',
            Telefone: '',
        });
        handleClose();
    }

    return (
        <Dialog open={open} onClose={ClearData}>
            <DialogTitle>Adicionar a Whitelist</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Nome"
                    type="text"
                    fullWidth
                    value={whitelistDados.Nome}
                    onChange={e => setWhiteListDados({...whitelistDados, Nome: e.target.value})}
                />
                <TextField
                    margin="dense"
                    label="Email"
                    type="text"
                    fullWidth
                    value={whitelistDados.Email}
                    onChange={e => setWhiteListDados({...whitelistDados, Email: e.target.value})}
                />
                <TextField
                    margin="dense"
                    label="Telefone"
                    type="text"
                    fullWidth
                    value={whitelistDados.Telefone}
                    onChange={e => setWhiteListDados({...whitelistDados, Telefone: e.target.value})}
                />
                <Select
                    sx={{
                        marginTop: '10px',
                    }}
                    value={whitelistDados.IdProduto}
                    onChange={e => setWhiteListDados({...whitelistDados, IdProduto: e.target.value})}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Plataforma de Integração' }}
                    fullWidth
                >
                    <MenuItem value="0" disabled>
                        Produto
                    </MenuItem>
                    {
                        produtosCliente && produtosCliente.map(p => {
                            return <MenuItem key={p.integracaoIdInternoValor} value={p.integracaoIdInternoValor}>{p.nomeProduto}</MenuItem>
                        })
                    }
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={ClearData}>Cancelar</Button>
                <Button onClick={() => handleSave(whitelistDados, ClearData)} color="primary">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default Whitelist;
