import react, { useState } from 'react';
import { Box, Button, Modal, TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React from "react";
import { useTelegramGrupos } from "../../../contexts/telegramGrupos.context";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ContainerCopyPaste } from "./style";
import { useProdutos } from "../../../contexts/produtos.context";

export default function ModalTabela({ open, onClose, data }) {
    const { GetGruposUsuario, gruposUsuario, convidarProGrupo, removerDoGrupo, linkGrupo, setLinkGrupo } = useTelegramGrupos();
    const [exibirDetalhesCompra, setExibirDetalhesCompra] = useState(false);
    const { GetUserProdutos, produtosUsuarioSelecionado, vendas, GetVendas, setLinkRemoverAssociado, linkRemoverAssociado, RemoverTelefoneAssociado, GerarLinkBotAssociacao, linkBotAssociacao, setLinkBotAssociacao } = useProdutos();
    const options = {
        filterType: 'checkbox',
        selectableRows: false,
        filter: false,
        viewColumns: false,
        responsive: 'simple'
    };

    const HandleExibirGrupos = (Id_Hotmart_Usuario) => {
        setExibirDetalhesCompra(true);
        GetGruposUsuario(Id_Hotmart_Usuario);
    }

    const columnAcoes = [
        {
            name: "Id Hotmart Usuario Compra",
            options: {
                display: false
            }
        },
        "Hotmart Codigo Interno Compra",
        "Nome Produto",
        "Status Compra",
        {
            name: "Link Boolean Remover Asociado",
            options: {
                display: false
            }
        },
        {
            name: "Link Bot Associacao",
            options: {
                display: false
            }
        },
        {
            label: "Ações",
            filter: false,
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Button onClick={() => HandleExibirGrupos(tableMeta.rowData[1])}>
                                    Ver Detalhes
                                </Button>
                                {tableMeta.rowData[4] === "true" &&
                                    <Button style={{ textAlign: "left" }} onClick={() => RemoverTelefoneAssociado(tableMeta.rowData[1])}>
                                        Remover Telefone Associado
                                    </Button>
                                }
                                {tableMeta.rowData[6] === "True" &&
                                    <Button style={{ textAlign: "left" }} onClick={() => GerarLinkBotAssociacao(tableMeta.rowData[5])}>
                                        Gerar Link Bot Associação
                                    </Button>
                                }
                            </div>
                        </>
                    )
                }
            }
        }
    ]

    const columnGrupos = [
        {
            name: "Id telegram Usuario",
            options: {
                display: false
            }
        },
        {
            name: "Telefone",
            options: {
                display: false
            }
        },
        "Telegram Id",
        {
            name: "Hotmart Id",
            options: {
                display: false
            }
        },
        {
            name: "Username",
            options: {
                display: false
            }
        },
        {
            name: "Nome",
            options: {
                display: false
            }
        },
        {
            name: "Sobrenome",
            options: {
                display: false
            }
        },
        {
            name: "Id Telegram Usuarios Grupo",
            options: {
                display: false
            }
        },
        {
            name: "Id Telegram Produto",
            options: {
                display: false
            }
        },
        {
            name: "Link Grupo",
            options: {
                display: false
            }
        },
        "Data Hora",
        "Nome do Grupo",
        {
            label: "Ações",
            filter: false,
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Button onClick={() => tableMeta.rowData[2] !== "" ? removerDoGrupo(tableMeta.rowData[2], tableMeta.rowData[12], tableMeta.rowData[0]) : convidarProGrupo(tableMeta.rowData[0], tableMeta.rowData[12], tableMeta.rowData[8])}>
                            {tableMeta.rowData[2] !== "" ? "Expulsar" : "Gerar Link"}
                        </Button>
                    )
                }
            }
        }
    ]

    // const columnAcoes = [
    //     "Id Hotmart Usuario Compra",
    //     "Hotmart Codigo Interno Compra",
    //     "Nome Produto",
    //     "Status Compra",
    //     {
    //         label: "Ações",
    //         options: {
    //             customBodyRender: (value, tableMeta, updateValue) => {
    //                 return (
    //                     <button onClick={() => gruposUsuario.some(e => e[0] === tableMeta.rowData[0])
    //                         ?
    //                         removerDoGrupo(tableMeta.rowData[0], usuarioIdSelecionado)
    //                         :
    //                         "Convidar"
    //                     }>
    //                         {gruposUsuario.some(e => e[0] === tableMeta.rowData[0]) ? "Expulsar" : "Convidar"}
    //                     </button>
    //                 )
    //             }
    //         }
    //     }
    // ]

    return (
        <>
            <Modal
                onClose={onClose}
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <MUIDataTable
                        title={"Produtos"}
                        data={data}
                        columns={columnAcoes}
                        options={options}
                    />
                </Box>
            </Modal>

            <Modal
                onClose={() => setExibirDetalhesCompra(false)}
                open={exibirDetalhesCompra}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <MUIDataTable
                        title={"Grupos"}
                        data={gruposUsuario}
                        columns={columnGrupos}
                        options={options}
                    />
                </Box>
            </Modal>

            {linkGrupo &&
                <Modal
                    onClose={() => setLinkGrupo("")}
                    open={linkGrupo != ""}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <ContainerCopyPaste>
                            <TextField variant="standard" value={linkGrupo} />
                            <Button variant="contained" onClick={() => navigator.clipboard.writeText(linkGrupo)} variant="outlined" startIcon={<ContentCopyIcon />}>
                                Copiar
                            </Button>
                        </ContainerCopyPaste>
                    </Box>
                </Modal>
            }

            {linkBotAssociacao &&
                <Modal
                    onClose={() => setLinkBotAssociacao("")}
                    open={linkBotAssociacao != ""}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <ContainerCopyPaste>
                            <TextField variant="standard" value={linkBotAssociacao} />
                            <Button variant="contained" onClick={() => navigator.clipboard.writeText(linkBotAssociacao)} variant="outlined" startIcon={<ContentCopyIcon />}>
                                Copiar
                            </Button>
                        </ContainerCopyPaste>
                    </Box>
                </Modal>
            }

            <Modal
                onClose={() => setLinkRemoverAssociado("")}
                open={linkRemoverAssociado != ""}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ContainerCopyPaste>
                        <TextField variant="standard" value={linkRemoverAssociado} />
                        <Button variant="contained" onClick={() => navigator.clipboard.writeText(linkRemoverAssociado)} variant="outlined" startIcon={<ContentCopyIcon />}>
                            Copiar
                        </Button>
                    </ContainerCopyPaste>
                </Box>
            </Modal>
        </>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
