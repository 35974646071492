import styled from 'styled-components';
import FundoSobrevivente from '../../../assets/fundo-osobrevivente.jpg';
import FundoSobreviventeMobile from '../../../assets/fundo-login-mobile.png';

export const Paineis = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-color: #FFF;

  @media only screen and (max-width: 1023px) {
    background-color: #FFF;
    display: flex;
    height: ${(props) => (props.register ? '100%' : '100vh')};

    justify-content: center;
    .jogadorImg {
      display: none;
    }
  }
`;
export const PainelDireito = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00152d90;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 1023px) {
    background: none;
    box-shadow: none;
    .Logo {
      width: 60%;
      height: 60%;
      align-self: center;
      margin: 10vh 0 5vh 0;
    }
  }
`;
export const ConteudoDireito = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const AlinharCentro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const PainelEsquerdo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;
export const ModalStyle = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  color: #fff;
  background: rgba(45, 69, 95, 1);
  border: 1px solid #6885a3;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1);
  padding: 21px;
  overflow: scroll;
`;
export const Label = styled.div`
  display: flex;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #c9d5e1;
  margin-bottom: 8px;
  color: #ffffff;
`;
export const ErrorMessage = styled.div`
  color: red;
  margin-top: 5px;
  font-size: 0.7em;
`;

export const BlockColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 18px;
`;
