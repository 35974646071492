import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    ReactElement,
} from 'react';

import instance from '../services/instance';
import {useAuth} from "./autenticacao.context";
import {useLoading} from "./loading.context";

const UsuariosInternoContext = createContext({});

export const UsuariosInternoProvider = ({ children }) => {
    const {user} = useAuth();
    const {setLoadingAtivo} = useLoading();

    const [usuariosInterno, setUsuariosInterno] = useState(null);
    const [usuarioInternoSelecionado, setUsuarioInternoSelecionado] = useState(null)
    const [usuarioInternoADeletar, setUsuarioInternoADeletar] = useState(null)

    const GetUsuariosInterno = async () => {
        setLoadingAtivo(true)
        await instance.get("api/LineraPay/exibirUsuariosInternos", { params: { ClienteId: user.ClientId } })
            .then(r => {
                setUsuariosInterno(r.data)
                setLoadingAtivo(false)
            })
            .catch(e => {
                console.error(e)
                setLoadingAtivo(false)
            })
    }

    //adicionar as coisas novas que precisam ser passadas
    const AdicionarUsuarioInterno = async (usuario) => {
        setLoadingAtivo(true)
        await instance.post("/api/token/registrar", {
            ClienteId: user.ClientId,
            Login: usuario.nome,
            Senha: usuario.senha,
            Email: usuario.email
        })
        await GetUsuariosInterno();
        setLoadingAtivo(false)
    }

    const EditarUsuarioInterno = async (usuario) => {
        
    }

    const DeletarUsuarioInterno = async () => {
        
        await instance.delete("/api/LineraPay/deletarUsuarioInterno", {
            data: {
              Email: usuarioInternoADeletar,
            },
          })
        setLoadingAtivo(true)
        setUsuarioInternoADeletar(null)
        await GetUsuariosInterno();
        setLoadingAtivo(false)
    }

    const SoliciatarRecuperacaoDeSenha = async (email) => {
        setLoadingAtivo(true)
        await instance.post("/api/token/solicitarRecuperacaoSenha", {Email: email})
        await GetUsuariosInterno();
        setLoadingAtivo(false)
    }

    return (
        <UsuariosInternoContext.Provider value={
            {
                usuariosInterno,
                setUsuariosInterno,
                usuarioInternoSelecionado,
                setUsuarioInternoSelecionado,
                GetUsuariosInterno,
                AdicionarUsuarioInterno,
                DeletarUsuarioInterno,
                usuarioInternoADeletar,
                setUsuarioInternoADeletar,
                SoliciatarRecuperacaoDeSenha
            }}>
            {children}
        </UsuariosInternoContext.Provider>
    );
};

export function useUsuariosInterno() {
    const context = useContext(UsuariosInternoContext);

    return context;
}
