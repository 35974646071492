import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import ModalAdicionarUsuarioInterno from "./ModalAdicionarUsuarioInterno";
import DeleteUserModal from "./DeletarUsuarioModal";
import {Button} from "@mui/material";
import {BotaoContained} from "../../StyledComponents";
import {useUsuariosInterno} from "../../../contexts/usuariosinterno.context";
import {FormatData} from "../../../utils/dateFormater";

const UsuariosInternos = () => {
    const {
        usuariosInterno,
        setUsuariosInterno,
        usuarioInternoSelecionado,
        setUsuarioInternoSelecionado,
        GetUsuariosInterno,
        AdicionarUsuarioInterno,
        DeletarUsuarioInterno,
        usuarioInternoADeletar,
        setUsuarioInternoADeletar,
        SoliciatarRecuperacaoDeSenha
    } = useUsuariosInterno();

    const [abrirModalAdicionar, setAbrirModalAdicionar] = useState(false)

    const columns = [
        {
            name: "dataUsuario",
            label: "Data Usuario",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                <p>
                                    {FormatData(tableMeta.rowData[0])}
                                </p>
                            </div>
                        </>
                    )
                }
            }
        },
        {
            name: "login",
            label: "Login",
        },
        {
            name: "nome",
            label: "Nome",
        },
        {
            name: "chaveRecuperacaoSenha",
            label: "chaveRecuperacaoSenha",
            options: {
                display: false,
            }
        },
        {
            name: "email",
            label: "Email",
        },
        {
            name: "clienteId",
            label: "Cliente ID",
            options: {
                display: false
            }
        },
        {
            name: "idUsuario",
            label: "Id Usuario",
            options: {
                display: false
            }
        },
        {
            name: "senha",
            label: "senha",
            options: {
                display: false,
            }
        },
        {
            name: "actions",
            label: "Ações",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                <Button onClick={() => SoliciatarRecuperacaoDeSenha(tableMeta.rowData[3]) }>
                                    Resetar Senha
                                </Button>
                                <Button onClick={()=>
                                    {
                                        setUsuarioInternoADeletar(tableMeta.rowData[4])
                                        console.log(tableMeta.rowData[4])
                                        DeletarUsuarioInterno()
                                        }}>
                                    Deletar usuario
                                </Button>
                            </div>
                        </>
                    )
                }
            }
        },
    ];

    const options = {
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        pagination: false,
        responsive: 'simple'
    };

    useEffect(() => {
        GetUsuariosInterno();
    }, [])

    if(usuariosInterno)
        return (
            <>
                <div style={{width: '100%'}}>
                    <BotaoContained variant={"contained"} style={{ margin: '16px' }} onClick={() => setAbrirModalAdicionar(true)}>
                        Adicionar Usuario
                    </BotaoContained>
                    <MUIDataTable
                        title={"Usuarios Internos"}
                        data={usuariosInterno}
                        columns={columns}
                        options={options}
                    />
                </div>

                <ModalAdicionarUsuarioInterno open={abrirModalAdicionar} handleClose={() => setAbrirModalAdicionar(false)} />
                <DeleteUserModal open={usuarioInternoADeletar != null} onDelete={() => DeletarUsuarioInterno()} onClose={() => setUsuarioInternoADeletar(null)} />
            </>
        );

    return null
}

export default UsuariosInternos;
